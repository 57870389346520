import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

import AddBoxIcon from "@mui/icons-material/AddBox";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

import EventRow from "./EventRow";

import { getEvents } from "../../../actions/events";
import { RCTV_EVENT } from "../../../@types";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";

interface EventTabProps {
  handleDeleteClick: Function;
}

function EventTab({ handleDeleteClick }: EventTabProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const events = useAppSelector((state) => state.events.events);
  const status = useAppSelector((state) => state.events.status);
  const [savedEvents, setEvents] = useState<Array<RCTV_EVENT>>([]);
  const [editMode, toggleEditMode] = useState<boolean>(false);
  const handleEditModeClick = () => toggleEditMode(!editMode);
  const handlePlusIconClick = () => navigate("/admin/events/create");
  useEffect(() => {
    if (status === "idle") {
      dispatch(getEvents());
    }
    if (status === "succeeded") {
      setEvents(events);
    }
  }, [events, status, dispatch]);
  const eventsToRender =
    savedEvents &&
    savedEvents.length > 0 &&
    savedEvents
      .filter((event) => event.title.toLocaleLowerCase() !== "pay day")
      .map((event) => {
        return (
          <EventRow
            key={event.id}
            editMode={editMode}
            event={event}
            handleDeleteClick={handleDeleteClick}
          />
        );
      });

  const editModeColor = editMode ? "#0099CC" : "#FF8800";

  return (
    <article className="event-tab__container">
      <header className="event-tab__header tab__header">Events</header>
      <section className="event-tab__actions__wrapper">
        <article className="event-tab__actions">
          <IconButton onClick={handlePlusIconClick} sx={{ color: "#00c851" }}>
            <AddBoxIcon />
          </IconButton>
          <IconButton
            onClick={handleEditModeClick}
            sx={{ color: editModeColor }}
          >
            <ModeEditOutlineIcon />
          </IconButton>
        </article>
      </section>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Departments</TableCell>
              {editMode && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>{eventsToRender}</TableBody>
        </Table>
      </TableContainer>
    </article>
  );
}

export default EventTab;
