import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import Skeleton from "@mui/material/Skeleton";

import EventForm from "../../components/EventForm";
import RCTVSnackbar from "../../components/Snackbar";

import {
  ConfirmDataTypes,
  Department,
  EventFormData,
  RCTV_EVENT,
  SNACKBAR_STATUSES,
} from "../../@types";
import { INITIAL_EVENT_FORM } from "../../common/constants";
import checkIfSuperUser from "../../modules/checkIfSuperUser";
import formatDateTimeForDjango from "../../modules/formatDateTimeForDjango";
import getTimeFromDate from "../../modules/getTimeFromDate";
import { useAppDispatch } from "../../config/hooks";
import { editEvent, getEvent } from "../../actions/events";

import "../CreateEvent/styles.scss";

interface EditEventParams {
  id?: string | number;
}

function EditEvent() {
  const isSuperUser = checkIfSuperUser();
  const dispatch = useAppDispatch();
  const { id }: EditEventParams = useParams();
  const [eventData, updateEventData] =
    useState<EventFormData>(INITIAL_EVENT_FORM);
  const [snackbarOpen, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<SNACKBAR_STATUSES>(
    SNACKBAR_STATUSES.SUCCESS
  );
  const [renderForm, toggleForm] = useState<boolean>(false);

  useEffect(() => {
    async function getEventFromParams(rctvId: string | number) {
      const { payload } = await dispatch(getEvent(rctvId));
      const retrievedEvent: RCTV_EVENT = payload.data[0] as RCTV_EVENT;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: _eventId, ...data } = retrievedEvent;
      const dataToUpdate: EventFormData = {
        ...data,
        start_time: getTimeFromDate(data.start_date as string),
        end_time: getTimeFromDate(data.end_date as string),
      };
      updateEventData(dataToUpdate);
      toggleForm(true);
    }
    if (id) {
      getEventFromParams(id);
    }
  }, [dispatch, id]);

  const showSnackbarMessage = (msg: string, severity: SNACKBAR_STATUSES) => {
    setSnackbarMessage(msg);
    setSnackbarSeverity(severity);
    return setSnackbar(true);
  };
  const handleConfirm = async (data: ConfirmDataTypes) => {
    // format dates
    const eventData = data as EventFormData;
    const {
      start_date: startDate,
      end_date: endDate,
      title: eventTitle,
      event_description: eventDescription,
      tags,
      start_time,
      end_time,
      rrule,
    } = eventData;
    const formattedStartDate = formatDateTimeForDjango(startDate, start_time);
    const formattedEndDate = formatDateTimeForDjango(endDate, end_time);
    // get all data
    const tagsToSend = tags as unknown as Array<Department>;
    const rctvId = id as string | number;
    const dataObj: RCTV_EVENT = {
      id: rctvId,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      title: eventTitle,
      event_description: eventDescription,
      tags: tagsToSend,
    };
    if (rrule) {
      const rrulestr = rrule.toString();
      dataObj.rrulestr = rrulestr;
    }
    // do api call
    const res = await dispatch(editEvent(dataObj));
    // get response
    if (res.type === "EDIT_EVENT/fulfilled") {
      // render snackbar
      return showSnackbarMessage(
        "Successfully edited event!",
        SNACKBAR_STATUSES.SUCCESS
      );
    }
    return showSnackbarMessage(
      "Unable to edit event, please contact Jonathan",
      SNACKBAR_STATUSES.ERROR
    );
  };
  const content = renderForm ? (
    <EventForm
      handleConfirm={handleConfirm}
      eventData={eventData}
      isSuperUser={isSuperUser}
    />
  ) : (
    <Skeleton width="100%" />
  );
  return (
    <div id="event-page__container">
      <nav>
        <Link to="/admin">
          <article className="go-back">
            <HomeIcon />
            <h6>Go back to admin screen</h6>
          </article>
        </Link>
      </nav>
      <main className="create-events__container">
        <header className="create-events__header">
          <h4>Edit Event</h4>
        </header>
        {content}
      </main>
      <RCTVSnackbar
        isOpen={snackbarOpen}
        severity={snackbarSeverity}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </div>
  );
}

export default EditEvent;
