import { useNavigate } from "react-router";
import { rrulestr } from "rrule";

import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

import { RCTV_EVENT } from "../../../@types";
import formatDateInTable from "../../../modules/formatDateInTable";
import checkIfUserHasPrivileges from "../../../modules/checkIfUserHasPrivileges";
import checkIfSuperUser from "../../../modules/checkIfSuperUser";

interface EventRowProps {
  editMode: boolean;
  event: RCTV_EVENT;
  handleDeleteClick: Function;
}

function EventRow({ editMode, event, handleDeleteClick }: EventRowProps) {
  const {
    id: eventId,
    start_date,
    title,
    event_description,
    tags,
    end_date,
    rrulestr: rrulestrToParse,
  } = event;
  const navigate = useNavigate();
  let renderRrule = rrulestrToParse && rrulestrToParse.length > 0;
  let rruleStrRender = "";
  if (rrulestrToParse && rrulestrToParse.length > 0) {
    try {
      const parsedRrule = rrulestr(rrulestrToParse);
      rruleStrRender = parsedRrule.toText();
    } catch (err) {
      renderRrule = false;
    }
  }
  const formattedStartTime = formatDateInTable(new Date(start_date));
  const formattedEndTime = formatDateInTable(new Date(end_date));
  const handleEditIconClick = () => navigate(`/admin/events/edit/${eventId}`);
  const handleDeleteIconClick = () => handleDeleteClick(event);
  const userHasPrivileges = checkIfUserHasPrivileges(event);
  const isSuperUser = checkIfSuperUser();
  const showControls = isSuperUser || userHasPrivileges;
  return (
    <TableRow>
      <TableCell>{formattedStartTime}</TableCell>
      <TableCell>{formattedEndTime}</TableCell>
      <TableCell>
        {title}
        {renderRrule ? (
          <span className="repeating-rule__text"> ({rruleStrRender})</span>
        ) : (
          ""
        )}
      </TableCell>
      <TableCell>{event_description}</TableCell>
      <TableCell>{tags.join(", ")}</TableCell>
      {showControls && editMode && (
        <TableCell data-testid="edit-actions">
          <IconButton onClick={handleEditIconClick} sx={{ color: "#ffbb33" }}>
            <ModeEditOutlineIcon />
          </IconButton>
          <IconButton onClick={handleDeleteIconClick} sx={{ color: "#ff4444" }}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
}

export default EventRow;
