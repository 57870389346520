import { YoutubeVideo } from "../../@types";

interface MainVideoProps {
  mainVideoDetails: YoutubeVideo;
}

function MainVideo({ mainVideoDetails }: MainVideoProps) {
  const { date_published: datePublished } = mainVideoDetails;
  const dateString = new Date(datePublished).toLocaleString("en-us", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  return (
    <article>
      <iframe
        width="560"
        height="315"
        allowFullScreen
        src={`//www.youtube.com/embed/${mainVideoDetails.video_id}?rel=0`}
        title={mainVideoDetails.video_title}
      />
      <section className="video-info__container">
        <summary className="video-info__title">
          {mainVideoDetails.video_title}
        </summary>
        <caption className="video-info__description ">
          {mainVideoDetails.video_description}
        </caption>
        <caption className="video-info__date">{dateString}</caption>
      </section>
    </article>
  );
}

export default MainVideo;
