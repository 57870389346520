import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";

import PostContent from "../../components/Posts/PostContent";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { POST } from "../../@types";

import getPostByIdDjango from "../../modules/getPostByIdDjango";
import { INITIAL_POST } from "../../common/constants";

interface PostPageParams {
  id?: string | number;
}

export default function PostPage() {
  const [postToUse, setPost] = useState<POST>(INITIAL_POST);
  const [postError, setError] = useState<boolean>(false);
  const { id }: PostPageParams = useParams();
  const navigate = useNavigate();
  const handleGoHomeClick = () => {
    navigate(-1);
  };
  useEffect(() => {
    getPostByIdDjango(setPost, id as string).catch((_e) => {
      setError(true);
    });
  }, [id]);
  if (postError) {
    return (
      <div
        className="error-message__container"
        data-testid="error-message-container"
      >
        <header>404 Error</header>
        <section>
          <h4>Post does Not Exist!</h4>
          <Link to="/">Go Home</Link>
        </section>
      </div>
    );
  }
  return (
    <div className="story__container">
      <nav className="story__nav">
        <span className="go-back__wrapper" onClick={handleGoHomeClick}>
          <ArrowBackIcon />
        </span>
      </nav>
      <PostContent post={postToUse} />
    </div>
  );
}
