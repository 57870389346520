import { useState } from "react";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";

import FaqForm from "../../components/FaqForm";
import RCTVSnackbar from "../../components/Snackbar";

import getUserDepartment from "../../modules/getUserDepartment";
import checkIfSuperUser from "../../modules/checkIfSuperUser";

import { createFaqs } from "../../actions/faqs";

import {
  Department,
  FAQFormData,
  FAQ_REQUEST,
  SNACKBAR_STATUSES,
} from "../../@types";

import { useAppDispatch } from "../../config/hooks";

import "./styles.scss";

function CreateFaq() {
  const isSuperUser = checkIfSuperUser();
  const [question, setQuestion] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");
  const [department, setDepartment] = useState<Department>("Human Resources");
  const [snackbarOpen, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<SNACKBAR_STATUSES>(
    SNACKBAR_STATUSES.SUCCESS
  );

  const resetState = () => {
    setQuestion("");
    setAnswer("");
    setDepartment("All");
  };

  const dispatch = useAppDispatch();
  let userDepartment = getUserDepartment();
  let userDepartmentToSet: Array<Department> = userDepartment
    ? [userDepartment]
    : ["All"];

  const updateState = (
    value: boolean | Array<string> | string,
    key: string
  ) => {
    let valToSet: boolean | Array<string> | string = value as string;
    if (key === "question") {
      return setQuestion(valToSet);
    }
    if (key === "answer") {
      return setAnswer(valToSet);
    }
    if (key === "department") {
      return setDepartment(valToSet as Department);
    }
  };

  const handleConfirm = async () => {
    if (isSuperUser) {
      userDepartmentToSet = [department];
    }
    const dataToSend: FAQ_REQUEST = {
      question: question,
      answer: answer,
      tags: userDepartmentToSet,
    };
    const res = await dispatch(createFaqs(dataToSend));

    let msg = "";
    if (res.meta.requestStatus === "rejected") {
      msg += "Unable to create new FAQ! Please contact Jonathan for assistance";
      setSnackbarMessage(msg);
      setSnackbarSeverity(SNACKBAR_STATUSES.ERROR);
      return setSnackbar(true);
    }

    msg += "Created new FAQ!";
    setSnackbarMessage(msg);
    setSnackbarSeverity(SNACKBAR_STATUSES.SUCCESS);
    setSnackbar(true);
    resetState();
  };
  const formData: FAQFormData = {
    question,
    answer,
    department,
  };

  const content = <FaqForm updateState={updateState} formData={formData} />;
  return (
    <div id="post-page__container">
      <nav>
        <Link to="/admin">
          <article className="go-back">
            <HomeIcon />
            <h6>Go back to admin screen</h6>
          </article>
        </Link>
      </nav>
      <main>
        <header>
          <h4>Create FAQ</h4>
        </header>
        {content}
        <article>
          <Button onClick={handleConfirm}>Save FAQ</Button>
        </article>
        <RCTVSnackbar
          isOpen={snackbarOpen}
          severity={snackbarSeverity}
          setSnackbar={setSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </main>
    </div>
  );
}

export default CreateFaq;
