import apiUrl from "../constants/apiUrl";

const getFaqByIdDjango = async (setState: any, id: string | number) => {
  const urlToUse = `${apiUrl}/faq/${id}`;
  const data = await fetch(urlToUse);
  const json = await data.json();
  return setState(json.data[0]);
};

export default getFaqByIdDjango;
