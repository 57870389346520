import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import noImgAvailable from "../../../assets/images/No_Image_Available.jpg";

import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import FormModal from "../../../components/FormModal";
import BackButton from "../../../components/BackButton";
import MakeProfileButton from "../../../components/Profiles/ProfileButton";

import { RCTVFile } from "../../../@types";
import { LOGO_IMAGES } from "../../../common/constants";
import Profiles from "../../../components/Profiles";

import { clearProfiles } from "../../../actions/profiles";
import { useAppDispatch } from "../../../config/hooks";

const DEPARTMENT = "Marketing";

function MarketingTeam() {
  const dispatch = useAppDispatch();
  const [showFilesModal, toggleFilesModal] = useState<boolean>(false);
  const [filesToShow, updateFilesToShow] = useState<Array<RCTVFile>>([]);
  const [modalHeader, setModalHeader] = useState<string>("");
  const handleLogosClick = () => {
    setModalHeader("Logos");
    updateFilesToShow(LOGO_IMAGES);
    toggleFilesModal(true);
  };
  useEffect(() => {
    dispatch(clearProfiles());
  }, [dispatch]);
  const closeFileModal = () => toggleFilesModal(false);
  return (
    <div className="App">
      <Sidebar />
      <main>
        <Header />
        <div className="posts__container">
          <section className="team__wrapper">
            <header className="posts__header__title">
              <article className="department__name__styling">
                <BackButton />
                <h4>Meet The Team:</h4>
              </article>
              <article className="button-position">
                <MakeProfileButton department={DEPARTMENT} />
              </article>
            </header>
            <article className="department-name-container">
              {DEPARTMENT}
            </article>
            <article className="team__bio">
              <Profiles department={DEPARTMENT} />
            </article>
          </section>
        </div>
      </main>
      <FormModal
        isOpen={showFilesModal}
        handleClose={closeFileModal}
        files={filesToShow}
        header={modalHeader}
      />
    </div>
  );
}

export default MarketingTeam;
