import { useEffect, useState } from "react";

import AdminProfile from "./AdminProfile";

import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getProfiles } from "../../actions/profiles";
import { PROFILE } from "../../@types";
import checkIfSuperUser from "../../modules/checkIfSuperUser";


interface AdminProfileProps {
  editMode: boolean;
  openDeleteModal: Function;
}

function AdminProfiles({ editMode, openDeleteModal }: AdminProfileProps) {
  const isSuperUser = checkIfSuperUser()
  const dispatch = useAppDispatch();
  const profileStatus = useAppSelector((state) => state.profiles.status);
  const profiles = useAppSelector((state) => state.profiles.profiles);
  const [savedProfiles, setSavedProfiles] = useState<Array<PROFILE>>([]);
  useEffect(() => {
    if (profileStatus === "idle") {
      dispatch(getProfiles());
    }
    if (profileStatus === "succeeded") {
      setSavedProfiles(profiles);
    }
  }, [profiles, profileStatus, dispatch]);

  const departmentProfiles = savedProfiles.map((p: PROFILE) => (
    <AdminProfile
      openDeleteModal={openDeleteModal}
      editMode={editMode}
      isSuperUser={isSuperUser}
      data={p}
      key={p.id}
    />
  ));

  return (
    <div>
      <ul>{departmentProfiles}</ul>
    </div>
  );
}

export default AdminProfiles;
