import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import { RCTVFile } from "../../@types";

import "./styles.scss";

interface FormModalProps {
  files: Array<RCTVFile>;
  header: string;
  isOpen: boolean;
  handleClose: Function;
}

function FormModal({ isOpen, handleClose, files, header }: FormModalProps) {
  const downloadImage = (fileName: string, path: string) =>
    fetch(path, {
      method: "GET",
      headers: {},
    })
      .then((res) =>
        res.arrayBuffer().then((buffer) => {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}.png`);
          document.body.appendChild(link);
          link.click();
        })
      )
      .catch((err) => console.log(err));
  const filesToRender = files.map((f) => {
    const handleDownload = () => downloadImage(f.name, f.path);
    return (
      <figure className="image-file__wrapper" key={f.name}>
        <IconButton sx={{ color: "#5cb85c" }} onClick={handleDownload}>
          <CloudDownloadIcon />
        </IconButton>
        <img className="image-file" src={f.path} alt={f.name} />
        <figcaption className="image-file__caption">{f.name}</figcaption>
      </figure>
    );
  });
  return (
    <Modal open={isOpen}>
      <Box>
        <div className="modal__container" id="delete-modal">
          <header className="modal__header">{header}</header>
          <section className="files__container">{filesToRender}</section>
          <footer>
            <Button
              variant="text"
              color="info"
              style={{ color: "grey" }}
              onClick={() => handleClose()}
            >
              Close
            </Button>
          </footer>
        </div>
      </Box>
    </Modal>
  );
}

export default FormModal;
