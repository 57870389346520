import { FunctionComponent } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Error404 from "./components/404";

import Home from "./pages/Home";
import About from "./pages/About";
import Admin from "./pages/Admin";
import Videos from "./pages/Videos";
import CreatePost from "./pages/CreatePost";
import Departments from "./pages/Departments";
import Pact from "./pages/Departments/Pact";
import It from "./pages/Departments/It";
import Finance from "./pages/Departments/Finance";
import Marketing from "./pages/Departments/Marketing";
import ItTeam from "./pages/Departments/It/ItTeam";
import MarketingTeam from "./pages/Departments/Marketing/MarketingTeam";
import HumanResources from "./pages/Departments/HumanResources";
import Events from "./pages/Events";
import EditFaq from "./pages/EditFaq";
import EditPost from "./pages/EditPost";
import CreateFaq from "./pages/CreateFaq";
import PostPage from "./pages/PostPage";
import CreateEvent from "./pages/CreateEvent";
import EditEvent from "./pages/EditEvent";
import FaqPage from "./pages/FaqPage";
import PactTeam from "./pages/Departments/Pact/PactTeam";
import FinanceTeam from "./pages/Departments/Finance/FinanceTeam";
import HumanResourcesTeam from "./pages/Departments/HumanResources/HumanResourcesTeam";
import CallCenterHome from "./pages/Departments/CallCenter";
import CoinTeamHome from "./pages/Departments/Shipping";
import CallCenterTeam from "./pages/Departments/CallCenter/CallCenterTeam";
import ShippingTeam from "./pages/Departments/Shipping/ShippingTeam";
import Production from "./pages/Departments/Production";
import PostProduction from "./pages/Departments/PostProduction";
import ReviewPost from "./pages/ReviewPost";
import CreateProfile from "./pages/CreateProfile";
import ProductionTeam from "./pages/Departments/Production/ProductionTeam";
import PostProductionTeam from "./pages/Departments/PostProduction/PostProductionTeam";
import EditProfile from "./pages/EditProfile";
import ReviewProfile from "./pages/ReviewProfile";

const IntranetRoutes: FunctionComponent = () => (
  <BrowserRouter>
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/create" element={<CreatePost />} />
        <Route path="/admin/faqs/create" element={<CreateFaq />} />
        <Route path="/admin/events/create" element={<CreateEvent />} />
        <Route path="/admin/events/edit/:id" element={<EditEvent />} />
        <Route path="/admin/edit/:id" element={<EditPost />} />
        <Route path="/admin/faqs/edit/:id" element={<EditFaq />} />
        <Route path="/admin/profile/edit/:id" element={<EditProfile />} />
        <Route path="/admin/review/post/:id" element={<ReviewPost />} />
        <Route path="/admin/profile/review/:id" element={<ReviewProfile />} />
        <Route path="/departments" element={<Departments />} />
        <Route path="/departments/marketing" element={<Marketing />} />
        <Route
          path="/departments/marketing/marketingteam"
          element={<MarketingTeam />}
        />
        <Route path="departments/it/itteam" element={<ItTeam />} />
        <Route path="departments/pact/pactteam" element={<PactTeam />} />
        <Route
          path="departments/finance/financeteam"
          element={<FinanceTeam />}
        />
        <Route
          path="/departments/callcenter/callcenterteam"
          element={<CallCenterTeam />}
        />
        <Route
          path="/departments/cointeam/shippingteam"
          element={<ShippingTeam />}
        />
        <Route
          path="/departments/production/productionteam"
          element={<ProductionTeam />}
        />
        <Route
          path="/departments/postproduction/postproductionteam"
          element={<PostProductionTeam />}
        />
        <Route
          path="/department/humanresources/hrteam"
          element={<HumanResourcesTeam />}
        />
        <Route path="/departments/pact" element={<Pact />} />
        <Route path="/departments/finance" element={<Finance />} />
        <Route path="/departments/it" element={<It />} />
        <Route path="/departments/callcenter" element={<CallCenterHome />} />
        <Route path="/departments/cointeam" element={<CoinTeamHome />} />
        <Route path="/events" element={<Events />} />
        <Route path="/faq/:id" element={<FaqPage />} />
        <Route path="/humanresources" element={<HumanResources />} />
        <Route path="/post/:id" element={<PostPage />} />
        <Route path="/profile/create" element={<CreateProfile />} />
        <Route path="/videos" element={<Videos />} />
        <Route element={<Error404 />} />
        <Route path="/departments/production" element={<Production />} />
        <Route
          path="/departments/post-production"
          element={<PostProduction />}
        />
        <Route path="/profilecreation" element={<CreateProfile />} />
      </Routes>
    </Layout>
  </BrowserRouter>
);

export default IntranetRoutes;
