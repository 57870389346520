import React from "react";

import { Route, Link, Routes, useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "./styles.scss";

function BackButton() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="circle">
      <ArrowBackIcon onClick={goBack} className="arrow-position" />
    </div>
  );
}

export default BackButton;
