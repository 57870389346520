import { useNavigate } from "react-router";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { FAQ } from "../../@types";

interface FaqProps {
  editMode: boolean;
  data: FAQ;
  openDeleteModal: Function;
}

function Faq({ editMode, data, openDeleteModal }: FaqProps) {
  const navigate = useNavigate();
  const goToEditPage = () => navigate(`/admin/faqs/edit/${data.id}`);
  const { question, answer, tags } = data;
  const handleDeleteModal = () => openDeleteModal(data);
  const deleteButton = editMode && (
    <article onClick={handleDeleteModal}>
      <span className="delete-faq__btn">X</span>
    </article>
  );
  const editButton = editMode && (
    <article onClick={goToEditPage}>
      <span className="edit-faq__btn">Edit</span>
    </article>
  );
  return (
    <div>
      <li className="admin-post__container">
        <article className="admin-post__actions">
          {deleteButton}
          {editButton}
        </article>
      </li>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="faq-question__text">
            {question}
            {tags.map((t) => (
              <sub className="faq__tag" key={t}>
                {t}
              </sub>
            ))}{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="faq-answer__text">{answer}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Faq;
