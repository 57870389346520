import { MenuProps as MenuPropsType } from "@mui/material/Menu";

import BlackLogoTagLine from "../assets/images/BlackLogoTagLine.png";
import BlackLogo from "../assets/images/BlackLogo.png";
import FinalWithTagLine from "../assets/images/FinalWithTagLine.png";
import FinalWithWebsiteTagLine from "../assets/images/FinalWithWebsiteTagline.png";
import JustAFinal from "../assets/images/JustAFinal.png";
import PactWithSmallTagline from "../assets/images/PactWithSmallTagline.png";
import RCTVSpelledOut from "../assets/images/RCTVSpelledOut.png";
import RCTVWhiteFull from "../assets/images/RCTVWhiteFull.png";
import Small from "../assets/images/Small.png";
import Small25 from "../assets/images/Small25.png";
import SmallBlackLogo from "../assets/images/SmallBlackLogo.png";
import SmallWhite from "../assets/images/SmallWhite.png";
import TVSectionLogo from "../assets/images/TVSectionLogo.png";
import SocialProfileLogo from "../assets/images/SocialProfileLogo.jpg";
import TobinGoogleLogo from "../assets/images/TobinGoogleLogo.png";
import TobinGoogleLogoWithBG from "../assets/images/TobinGoogleLogoWithBG.jpg";
import TobinGoogleWhiteLogo from "../assets/images/TobinGoogleWhiteLogo.jpg";
import WhiteLogoFinal from "../assets/images/WhiteLogoFinal.png";
import AnthemResourceAdvisor from "../assets/HR/AnthemResourceAdvisor.pdf";
import AnthemTravelAssistance from "../assets/HR/AnthemTravelAssistance.pdf";
import AnthemTravelAssistanceBrochure from "../assets/HR/AnthemTravelAssistanceBrochure.pdf";
import EquifaxAutomatedIncomeandEmploymentVerification from "../assets/HR/EquifaxAutomatedIncomeandEmploymentVerification.pdf";
import RCTVArizonaHandbookAddendumFinalMay2022 from "../assets/HR/RCTVArizonaHandbookAddendumFinalMay2022.pdf";
import RCTVCaliforniaHandbookFinalV2May2022 from "../assets/HR/RCTVCaliforniaHandbookFinalV2May2022.pdf";
import RCTVFloridaHandbookFinalMay2022 from "../assets/HR/RCTVFloridaHandbookAddendumFinalMay2022.pdf";
import RCTVMichiganHandbookFinalMay2022 from "../assets/HR/RCTVMichiganHandbookAddendumFinalMay2022.pdf";
import RCTVNationalHandbookFinalMay2022 from "../assets/HR/RCTVNationalHandbookFinalMay2022.pdf";
import RCTVNevadaHandbookAddendumFinalMay2022 from "../assets/HR/RCTVNevadaHandbookAddendumFinalMay2022.pdf";
import RCTVOklahomaHandbookAddendumFinalAugust2022 from "../assets/HR/RCTVOklahomaHandbookAddendumFinalAugust2022.pdf";
import RCTVTexasHandbookAddendumFinalMay2022 from "../assets/HR/RCTVArizonaHandbookAddendumFinalMay2022.pdf";
import RCTVVirginaHandbookAddendumFinalMay2022 from "../assets/HR/RCTVArizonaHandbookAddendumFinalMay2022.pdf";
import QuickGuide from "../assets/HR/WCMEMICQuickGuide.pdf";
import EmployeeAnthemResourceGuide from "../assets/HR/scan0082.pdf";

import {
  AdminUser,
  ADMIN_TAB_OPTIONS,
  Department,
  FAQFormData,
  PostFormData,
  POST,
  FAQ,
  RCTVFile,
  PostFormat,
  EventFormData,
  RCTV_EVENT,
  HR_FILE,
  ProfileFormData,
  HR_SUBSECTION_BLOCK,
  DEPARTMENT_COLOR,
} from "../@types";

export const DEPARTMENTS: Array<Department> = [
  "All",
  "Call Center & Customer Service",
  "PACT",
  "Marketing",
  "Finance",
  "Warehouse & Shipping",
  "Human Resources",
  "IT",
  "Production",
  "Post Production",
];

export const TINY_API_KEY = "tix4h4fe7bkvxcynifa117svo0hkb58e43wo4etndf950je2";

const currentDate = new Date();
export const INITIAL_POST_FORM: PostFormData = {
  title: "",
  pdf_link: "",
  subtitle: "",
  header_img: undefined,
  pdf_img: undefined,
  tags: [],
  body: "",
  publish_date: new Date(),
  archived_date: new Date(currentDate.setMonth(currentDate.getMonth() + 6)),
  format: "text",
  video_link: "",
};

export const INITIAL_EVENT_FORM: EventFormData = {
  start_date: "",
  end_date: "",
  tags: [],
  event_description: "",
  title: "",
  start_time: "",
  end_time: "",
};
export const INITIAL_EVENT: RCTV_EVENT = {
  id: "-1",
  start_date: "",
  end_date: "",
  tags: [],
  event_description: "",
  title: "Test Event",
};

export const INITIAL_FAQ_FORM: FAQFormData = {
  question: "",
  tags: [],
  department: DEPARTMENTS[0],
  answer: "",
};

export const INITIAL_PROFILE_FORM: ProfileFormData = {
  employee_name: "",
  position: "",
  email: "",
  department: "",
  image: "",
  approval_date: "",
  approved_by: "",
  date_modified: "",
  date_created: "",
};

export const YOUTUBE_KEY: string = "AIzaSyCDAr04V-FsYGH-8iBlTVfI_8kb2Jew-J4";

export const YOUTUBE_DATA_URL = "https://www.googleapis.com/youtube/v3/videos";

export const adminUsers: Array<AdminUser> = [
  {
    username: "nbaird",
    password: "marketingTeam101!",
    department: "Marketing",
  },
  {
    username: "rbrewster",
    password: "marketingTeam100!",
    department: "Marketing",
  },
  {
    username: "alinde",
    password: "Tiger99%",
    department: "Marketing",
  },
  {
    username: "esoffer",
    password: "Ninja007$",
    department: "All",
  },
  {
    username: "crobin",
    password: "Galaxy55$",
    department: "Marketing",
  },
  {
    username: "pstaben",
    password: "Victory7@",
    department: "Marketing",
  },
  {
    username: "erowley",
    password: "Sunrise#",
    department: "Marketing",
  },
  {
    username: "cj",
    password: "Newdad2024!",
    department: "Marketing",
  },
  {
    username: "todonnell",
    password: "Starlight*",
    department: "All",
  },
  {
    username: "molie",
    password: "newEncarta100!",
    department: "All",
  },
  {
    username: "jperez",
    password: "Password1997!@#$",
    department: "All",
  },
  {
    username: "atodd",
    password: "Callcenterteam100!",
    department: "Call Center & Customer Service",
  },
  {
    username: "cramirez",
    password: "Pactteam100!",
    department: "PACT",
  },
  {
    username: "jallen",
    password: "Financeteam100!",
    department: "Finance",
  },
  {
    username: "pjarrells",
    password: "Shippingteam100!",
    department: "Warehouse & Shipping",
  },
  {
    username: "tcudeback",
    password: "Cointeam100!",
    department: "PACT",
  },
  {
    username: "ngriffin",
    password: "PACTteam101!",
    department: "PACT",
  },
  {
    username: "agutierrez",
    password: "Itrules2020!",
    department: "All",
  },
  {
    username: "rcastrence",
    password: "ProductionTeam2020!",
    department: "All",
  },
  {
    username: "hmarin",
    password: "PostProductionTeam2020!",
    department: "All",
  },
  {
    username: "csternshein",
    password: "Humanresourcesteam2023!",
    department: "All",
  },
  {
    username: "ndo",
    password: "CoinChaser24!@#$",
    department: "All",
  },
];

export const ADMIN_KEY = "authStatus";
export const ADMIN_TAB_KEY = "adminTabKey";
export const ADMIN_USER_KEY = "authUser";
export const SUPER_USER_KEY = "isSuperUser";
export const DEPARTMENT_KEY = "departmentKey";

export const LOGO_IMAGES: Array<RCTVFile> = [
  {
    name: "Black Logo With Tag Line",
    path: BlackLogoTagLine,
  },
  {
    name: "Black Logo",
    path: BlackLogo,
  },
  {
    name: "Final With Tagline",
    path: FinalWithTagLine,
  },
  {
    name: "Final With Website Tagline",
    path: FinalWithWebsiteTagLine,
  },
  {
    name: "Final Logo",
    path: JustAFinal,
  },
  {
    name: "Pact With A Small Tagline",
    path: PactWithSmallTagline,
  },
  {
    name: "RCTV Spelled Out",
    path: RCTVSpelledOut,
  },
  {
    name: "RCTV White Full",
    path: RCTVWhiteFull,
  },
  {
    name: "Small Logo",
    path: Small,
  },
  {
    name: "25% Small Logo",
    path: Small25,
  },
  {
    name: "Small Black Logo",
    path: SmallBlackLogo,
  },
  {
    name: "Small White Logo",
    path: SmallWhite,
  },
  {
    name: "TV Section Logo",
    path: TVSectionLogo,
  },
  {
    name: "Social Profile Logo",
    path: SocialProfileLogo,
  },
  {
    name: "Tobin Google Logo",
    path: TobinGoogleLogo,
  },
  {
    name: "Tobin Google Logo with Background",
    path: TobinGoogleLogoWithBG,
  },
  {
    name: "Tobin Google White Logo",
    path: TobinGoogleWhiteLogo,
  },
  {
    name: "Final White Logo",
    path: WhiteLogoFinal,
  },
];

export const INITIAL_TEST_POST: POST = {
  id: -1,
  title: "",
  tags: ["All"],
  subtitle: "",
  header_img:
    "https://liberty-pact.s3.us-west-1.amazonaws.com/J4919-613290f0.jpg",
  format: "text",
  body: "",
  date_modified: "",
  pdf_link: "",
  video_link: "",
  date_created: "",
  publish_date: "",
  archived_date: "",
  ceo_post: false,
};

export const INITIAL_POST: POST = {
  id: -1,
  title: "",
  pdf_link: "",
  tags: [],
  subtitle: "",
  header_img: "",
  format: "text",
  body: "",
  date_modified: "",
  date_created: "",
  publish_date: "",
  archived_date: "",
  ceo_post: false,
  video_link: "",
};

export const INITIAL_FAQ: FAQ = {
  department: "All",
  id: -1,
  question: "",
  tags: [],
  answer: "",
};

export const ADMIN_TABS: Array<ADMIN_TAB_OPTIONS> = [
  ADMIN_TAB_OPTIONS.POST,
  ADMIN_TAB_OPTIONS.FAQ,
  ADMIN_TAB_OPTIONS.EVENTS,
  ADMIN_TAB_OPTIONS.PROFILES,
  ADMIN_TAB_OPTIONS.DOCUMENTATION,
];

export const POST_FORMATS: Array<PostFormat> = [
  "text",
  "video",
  "image",
  "pdf",
];

export const TEST_MAIN_USER = "molie";
export const TEST_BAD_USER = "whodat";
export const TEST_OTHER_USER = "atodd";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const RCTVMenuProps: Partial<MenuPropsType> = {
  variant: "menu",
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface RRULEDay {
  [key: string]: string;
}

export const RRULE_DAY_HASHMAP: RRULEDay = {
  Fri: "FR",
  Sat: "SA",
  Sun: "SU",
  Mon: "MO",
  Tue: "TU",
  Wed: "WE",
  Thu: "TH",
};

export const HR_FILES: Array<HR_FILE> = [
  {
    filename: "RCTV Arizona Handbook Addendum Final May 2022",
    filePath: RCTVArizonaHandbookAddendumFinalMay2022,
    subsection: "Additional State Handbooks",
  },
  {
    filename: "RCTV California Handbook Final May 2022",
    filePath: RCTVCaliforniaHandbookFinalV2May2022,
    subsection: "Ca & National Handbook",
  },
  {
    filename: "RCTV Florida Handbook Addendum Final May 2022",
    filePath: RCTVFloridaHandbookFinalMay2022,
    subsection: "Additional State Handbooks",
  },
  {
    filename: "RCTV Michigan Handbook Addendum Final May 2022",
    filePath: RCTVMichiganHandbookFinalMay2022,
    subsection: "Additional State Handbooks",
  },
  {
    filename: "RCTV National Handbook Final May 2022",
    filePath: RCTVNationalHandbookFinalMay2022,
    subsection: "Ca & National Handbook",
  },
  {
    filename: "RCTV Nevada Handbook Addendum Final May 2022",
    filePath: RCTVNevadaHandbookAddendumFinalMay2022,
    subsection: "Additional State Handbooks",
  },
  {
    filename: "RCTV Oklahoma Handbook Addendum Final August 2022",
    filePath: RCTVOklahomaHandbookAddendumFinalAugust2022,
    subsection: "Additional State Handbooks",
  },
  {
    filename: "RCTV Texas Handbook Addendum Final May 2022",
    filePath: RCTVTexasHandbookAddendumFinalMay2022,
    subsection: "Additional State Handbooks",
  },
  {
    filename: "RCTV Virginia Handbook Addendum Final May 2022",
    filePath: RCTVVirginaHandbookAddendumFinalMay2022,
    subsection: "Additional State Handbooks",
  },
  {
    filename: "Anthem Resouce Advisor",
    filePath: AnthemResourceAdvisor,
    subsection: "Medical Benefits",
  },
  {
    filename: "Anthem Travel Assistance",
    filePath: AnthemTravelAssistance,
    subsection: "Medical Benefits",
  },
  {
    filename: "Anthem Travel Assistance Brochure",
    filePath: AnthemTravelAssistanceBrochure,
    subsection: "Medical Benefits",
  },
  {
    filename: "Equifax Automated Income and Employment Verification",
    filePath: EquifaxAutomatedIncomeandEmploymentVerification,
    subsection: "Medical Benefits",
  },
  {
    filename: "Worker Comp Quick Guide",
    filePath: QuickGuide,
    subsection: "Worker Comp",
  },
  {
    filename: "Employee Anthem Resource Guide",
    filePath: EmployeeAnthemResourceGuide,
    subsection: "Medical Benefits",
  },
];

export const SUBSECTIONS: Array<HR_SUBSECTION_BLOCK> = [
  {
    parent: "Benefits",
    name: "Medical Benefits",
  },
  {
    parent: "Benefits",
    name: "Retirement Benefits",
  },
  {
    parent: "Benefits",
    name: "Worker Comp",
  },
  {
    parent: "Handbooks",
    name: "Ca & National Handbook",
  },
  {
    parent: "Handbooks",
    name: "Additional State Handbooks",
  },
  {
    parent: "Paylocity",
    name: "Time & Labor",
  },
  {
    parent: "Paylocity",
    name: "Payroll",
  },
];

export const DEFAULT_DATE = "1901-01-01";
export const ADMIN_HOME_LINK = "/admin";
export const DEFAULT_POST_IMAGE =
  "https://soliloquywp.com/wp-content/uploads/2016/08/How-to-Set-a-Default-Featured-Image-in-WordPress.png";

export const WORKER_URL =
  "https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js";

export const DEPARTMENT_COLORS: Array<DEPARTMENT_COLOR> = [
  {
    departmentName: "All",
    textColor: "white",
    bgColor: "#0e4da4",
  },
  {
    departmentName: "Call Center & Customer Service",
    textColor: "black",
    bgColor: "#8ee4fe",
  },
  {
    departmentName: "PACT",
    textColor: "black",
    bgColor: "#10deb9",
  },
  {
    departmentName: "Finance",
    textColor: "black",
    bgColor: "#8ee4af",
  },
  {
    departmentName: "Marketing",
    textColor: "black",
    bgColor: "#fe74f8",
  },
  {
    departmentName: "Production",
    textColor: "black",
    bgColor: "#ff5959",
  },
  {
    departmentName: "Warehouse & Shipping",
    textColor: "black",
    bgColor: "#fbda84",
  },
  {
    departmentName: "Human Resources",
    textColor: "black",
    bgColor: "#ff9a24",
  },
  {
    departmentName: "IT",
    textColor: "black",
    bgColor: "#c1abfe",
  },
  {
    departmentName: "Post Production",
    textColor: "black",
    bgColor: "#ffa38e",
  },
];
