import React, { useState } from "react";

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import { MenuProps as MenuPropsType } from "@mui/material/Menu";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";

import { FormValue, ProfileFormData } from "../../@types";
import { DEPARTMENTS } from "../../common/constants";

interface FormProps {
  formData: ProfileFormData;
  updateState: (content: FormValue, key: string) => void;
  defaultDepartment: string | null;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps: Partial<MenuPropsType> = {
  variant: "menu",
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Form({ defaultDepartment, formData, updateState }: FormProps) {
  const val = [] as unknown as string;
  const [newTags, setNewTags] = useState<string>(val);
  const {
    employee_name: employeeName,
    position,
    email,
    image: existingImage,
    imageToSet,
  } = formData;
  const removeHeaderImg = () => updateState(null, "image");
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    } else {
      const files: FileList = e.target.files as FileList;
      const imgFile = files[0];
      const reader = new FileReader();
      reader.onload = (evt: ProgressEvent<FileReader>) => {
        if (!e.target.files) {
          return null;
        }
        updateState(
          {
            file: e.target.files[0],
            id: 0,
            src: evt.target?.result as string,
          },
          "image"
        );
      };
      reader.readAsDataURL(imgFile);
    }
  };

  const handleNameInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateState(e.target.value, "name");
  const handlePositionInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateState(e.target.value, "position");
  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateState(e.target.value, "email");
  const handleTagsInput = (e: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = e;
    setNewTags(value);
    updateState(value, "department");
  };

  const existingImageRender = existingImage && existingImage.length > 0 && (
    <figure className="existing-image__wrapper">
      <img
        style={{ width: "100%" }}
        alt="Existing profile "
        src={existingImage}
      />
      <figcaption>Existing Image</figcaption>
    </figure>
  );

  const imgInput = !imageToSet ? (
    <Fab color="primary" aria-label="Add Header Image">
      <input
        type="file"
        onChange={handleImageUpload}
        accept="image/*"
        style={{
          //make this hidden and display only the icon
          position: "absolute",
          top: "-35px",
          left: 0,
          height: "calc(100% + 36px)",
          width: "calc(100% + 5px)",
          outline: "none",
        }}
      />
      <AddPhotoAlternateIcon />
    </Fab>
  ) : (
    <article className="image-upload__container">
      <header>Profile Image:</header>
      {imageToSet && <img src={imageToSet.src} alt={imageToSet.src} />}
      <span className="remove-img__btn" onClick={removeHeaderImg}>
        X
      </span>
    </article>
  );
  return (
    <div>
      {existingImageRender}
      <form className="profile-form__container">
        <TextField
          label="Name"
          value={employeeName}
          onChange={handleNameInput}
        />
        <TextField
          label="Position"
          value={position}
          onChange={handlePositionInput}
        />
        <TextField label="Email" value={email} onChange={handleEmailInput} />
        {imgInput}
        {!defaultDepartment && (
          <Select
            // multiple
            input={<OutlinedInput />}
            onChange={handleTagsInput}
            value={newTags}
            MenuProps={MenuProps}
          >
            {DEPARTMENTS.map((dept) => (
              <MenuItem key={dept} value={dept}>
                {dept}
              </MenuItem>
            ))}
          </Select>
        )}
      </form>
    </div>
  );
}

export default Form;
