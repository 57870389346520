import { MutableRefObject, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

import { TINY_API_KEY } from "../../common/constants";
import uploadFilesToAws from "../../modules/uploadFilesToAws";
import { ImageState } from "../../@types";

interface TextEditorProps {
  editorRef: MutableRefObject<any>;
  savedStoryBody: string;
}

export default function TextEditor({
  editorRef,
  savedStoryBody,
}: TextEditorProps) {
  const [currentBody, setCurrentBody] = useState<string>(savedStoryBody);
  const handleEditorChange = () => {
    if (editorRef.current) {
      const latestContent = editorRef.current.getContent();
      setCurrentBody(latestContent);
    }
  };
  return (
    <Editor
      onInit={(_evt, editor) => (editorRef.current = editor)}
      initialValue={savedStoryBody}
      value={currentBody}
      onEditorChange={handleEditorChange}
      apiKey={TINY_API_KEY}
      plugins="advlist autolink lists link image charmap print preview anchor
                          searchreplace visualblocks code fullscreen
                          insertdatetime media table paste code help wordcount"
      init={{
        height: 500,
        menubar: false,
        toolbar: `undo redo | formatselect | bold italic backcolor | 
                      alignleft aligncenter alignright alignjustify | 
                      bullist numlist outdent indent | removeformat | image`,
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        images_upload_handler: async (blobInfo: any) => {
          return new Promise(async (resolve, reject) => {
            try {
              const data: ImageState = {
                file: blobInfo.blob(),
                id: blobInfo.id(),
                src: blobInfo.name(),
              };
              const rawImages = (await uploadFilesToAws([data])) as any;
              const [imageLink] = await Promise.all(rawImages);
              resolve(imageLink);
            } catch (e) {
              reject(e);
            }
          });
        },
      }}
    />
  );
}
