const formatDateForDjango = (date: Date | string): string => {
  const formattedDate = new Date(date);
  return new Date(
    formattedDate.getTime() - formattedDate.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[0];
};

export default formatDateForDjango;
