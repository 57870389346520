import { AnimatePresenceProps } from "framer-motion";
import { RRule } from "rrule";

export type Department =
  | "All"
  | "Call Center & Customer Service"
  | "PACT"
  | "Finance"
  | "Marketing"
  | "Production"
  | "Warehouse & Shipping"
  | "Human Resources"
  | "IT"
  | "Production"
  | "Post Production";

export type PostFormat = "video" | "text" | "image" | "pdf";

export type AdminItemType = "FAQ" | "Event" | "Post" | "Profile";

export interface POST {
  id: number;
  format: PostFormat;
  title: string;
  tags: Array<string>;
  subtitle: string;
  header_img: string;
  ceo_post: boolean;
  video_link: string;
  pdf_link: string;
  body: string;
  date_modified: string | Date;
  date_created: string | Date;
  publish_date: string | Date;
  archived_date: string | Date;
  approval_date?: string | Date;
  approved_by?: string | Date;
  body_images?: Array<string>;
}
export type UPDATE_POST_REQUEST = POST;

export type POST_REQUEST = Omit<UPDATE_POST_REQUEST, "id">;

export interface REACT_POST {
  data: POST;
}

export interface PostFormData
  extends Omit<
    POST_REQUEST,
    "ceo_post" | "header_img" | "date_modified" | "date_created"
  > {
  header_img: ImageState | undefined;
  body_img?: ImageState | undefined;
  pdf_img?: ImageState | undefined;
  existingBodyImage?: string;
  existingImage?: string;
  existingPdfImage?: string;
  video_link: string;
}

export interface WEATHER_RES {
  current_temp: number;
  icon_url: string;
  weather_description: string;
}

export interface PROFILE {
  id: number;
  employee_name: string;
  position: string;
  email: string;
  department: string;
  image?: string;
  date_modified?: string | Date;
  date_created: string | Date;
  approval_date?: string | Date;
  approved_by?: string | Date;
}

export interface ProfileFormData extends Omit<PROFILE, "id"> {
  imageToSet?: ImageState;
}

export interface UPDATE_PROFILE_REQUEST extends Omit<PROFILE, "date_created"> {}

export type CREATE_PROFILE_REQUEST = Omit<PROFILE, "id">;

export interface IPostFormFaq {
  question: string;
  answer: string;
  department: Department;
  tags?: Array<string>;
}

export interface FAQ {
  id: number;
  question: string;
  answer: string;
  tags: Array<string>;
  department: Department;
}

export interface REACT_FAQ {
  data: FAQ;
}

export interface FAQFormData {
  question: string;
  answer: string;
  department: Department;
  tags?: Array<string>;
}

export interface FAQ_REQUEST {
  id?: string | number;
  question: string;
  answer: string;
  tags?: Array<string>;
  department?: Department;
}

export interface ImageState {
  file: File;
  id: number;
  src: string;
}

export enum SNACKBAR_STATUSES {
  "SUCCESS" = "success",
  "ERROR" = "error",
}

export enum ADMIN_TAB_OPTIONS {
  "POST" = "Posts",
  "FAQ" = "FAQ",
  "EVENTS" = "Events",
  "DOCUMENTATION" = "Documentation",
  "PROFILES" = "Profiles",
}

export interface SidebarOption {
  icon: any;
  text: string;
  activePath: string;
}

export interface YoutubeCategory {
  id: number | string;
  playlist_id: string;
  playlist_name: string;
}

export interface YoutubeVideo {
  id: number | string;
  video_id: string;
  video_title: string;
  video_description: string;
  category_id: string;
  date_published: string;
  thumbnail: string;
}

export interface YoutubeVideoThumbnailDetails {
  height: number;
  width: number;
  url: string;
}

export interface YoutubeVideoThumbnail {
  default: YoutubeVideoThumbnailDetails;
  high: YoutubeVideoThumbnailDetails;
  maxres: YoutubeVideoThumbnailDetails;
  medium: YoutubeVideoThumbnailDetails;
  standard: YoutubeVideoThumbnailDetails;
}

export interface AdminUser {
  username: string;
  password: string;
  department: Department;
}

export interface RCTVFile {
  name: string;
  path: string;
}

export interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export interface RCTV_EVENT {
  start_date: string | Date;
  end_date: string | Date;
  id: string | number;
  title: string;
  event_description: string;
  tags: Array<Department>;
  rrulestr?: string;
  label_id?: number;
}
export interface RCTV_CALENDAR_EVENT extends Omit<RCTV_EVENT, "end_date"> {
  end_date?: string | Date;
}

export enum FREQUENCY_OPTION {
  "NONE" = "None",
  "DAILY" = "DAILY",
  "WEEKLY" = "WEEKLY",
  "BIWEEKLY" = "BIWEEKLY",
  "MONTHLY" = "MONTHLY",
  "YEARLY" = "YEARLY",
}

export interface EventFormData extends Omit<RCTV_EVENT, "id"> {
  start_time: string;
  end_time: string;
  rrule?: RRule;
}

export interface EVENT_REQUEST
  extends Omit<
    EventFormData,
    "start_time" | "end_date" | "end_time" | "rrule"
  > {
  rrulestr?: string;
  end_date?: string | Date;
}

export type ConfirmDataTypes = EventFormData | PostFormData | ProfileFormData;

export interface RCTVFormProps {
  handleConfirm: (data: ConfirmDataTypes) => Promise<void>;
  isSuperUser: boolean;
}

export type FormValue =
  | null
  | boolean
  | Array<string>
  | string
  | Date
  | ImageState
  | RRule
  | number;

export class RCTVDate extends Date {
  addDays(days: number) {
    const date = new RCTVDate(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }
}

export type RouteParams = {
  id?: string | number;
};

export type HR_SECTION = "Handbooks" | "Benefits" | "Paylocity";

export type HR_SUBSECTION =
  | "Ca & National Handbook"
  | "Additional State Handbooks"
  | "Medical Benefits"
  | "Retirement Benefits"
  | "Worker Comp"
  | "Time & Labor"
  | "Payroll";

export interface HR_SUBSECTION_BLOCK {
  parent: HR_SECTION;
  name: HR_SUBSECTION;
}

export type HR_FILE = {
  filename: string;
  filePath: any;
  subsection: HR_SUBSECTION;
};

export type DEPARTMENT_COLOR = {
  departmentName: Department;
  textColor: string;
  bgColor: string;
};

export interface NewAnimatePresenceProps
  extends Omit<AnimatePresenceProps, "children"> {
  children: React.ReactNode;
}
