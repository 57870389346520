import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apiUrl from "../constants/apiUrl";
import { FAQ_REQUEST } from "../@types";

const baseApiUrl = `${apiUrl}/faqs`;
const getFaqUrl = `${apiUrl}/faq`;

export const GET_FAQS = "GET_FAQS";
export const getFaqs = createAsyncThunk(GET_FAQS, async () => {
  const res: AxiosResponse = await axios.get(baseApiUrl);
  return res.data;
});

export const CREATE_FAQ = "CREATE_FAQ";
export const createFaqs = createAsyncThunk(
  CREATE_FAQ,
  async (faqData: FAQ_REQUEST) => {
    const res: AxiosResponse = await axios.post(baseApiUrl, faqData);
    return res.data;
  }
);
export const DELETE_FAQ = "DELETE_FAQ";
export const deleteFaq = createAsyncThunk(
  DELETE_FAQ,
  async (faqId: number | string) => {
    try {
      await axios.delete(`${baseApiUrl}/${faqId}`);
      return faqId;
    } catch (err) {
      return false;
    }
  }
);

export const clearFaqs = createAsyncThunk("CLEAR_FAQS", async () => null);

export const GET_FAQ = "GET_FAQ";
export const getFaq = createAsyncThunk(GET_FAQ, async (id: string | number) => {
  const res: AxiosResponse = await axios.get(`${getFaqUrl}/${id}`);
  return res.data;
});

export const EDIT_FAQ = "EDIT_FAQ";
export const editFaq = createAsyncThunk(
  EDIT_FAQ,
  async (faqToEdit: FAQ_REQUEST) => {
    try {
      const { id: faqId, ...faqBody } = faqToEdit;
      await axios.put(`${baseApiUrl}/${faqId}`, faqBody);
      return faqBody;
    } catch (err) {
      return null;
    }
  }
);
