import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apiUrl from "../constants/apiUrl";
import { POST, POST_REQUEST } from "../@types";

const baseApiUrl = `${apiUrl}/posts`;
const getPostUrl = `${apiUrl}/post`;

export const GET_POSTS = "GET_POSTS";
export const getPosts = createAsyncThunk(GET_POSTS, async () => {
  const res: AxiosResponse = await axios.get(baseApiUrl);
  return res.data;
});

export const CREATE_POST = "CREATE_POST";
export const createPosts = createAsyncThunk(
  CREATE_POST,
  async (postData: POST_REQUEST) => {
    const res: AxiosResponse = await axios.post(baseApiUrl, postData);
    return res.data;
  }
);
export const DELETE_POST = "DELETE_POST";
export const deletePost = createAsyncThunk(
  DELETE_POST,
  async (postId: number | string) => {
    try {
      await axios.delete(`${baseApiUrl}/${postId}`);
      return postId;
    } catch (err) {
      return false;
    }
  }
);

export const clearPosts = createAsyncThunk("CLEAR_POSTS", async () => null);

export const GET_POST = "GET_POST";
export const getPost = createAsyncThunk(
  GET_POST,
  async (id: string | number) => {
    const res: AxiosResponse = await axios.get(`${getPostUrl}/${id}`);
    return res.data;
  }
);

export const EDIT_POST = "GET_POST";
export const editPost = createAsyncThunk(
  EDIT_POST,
  async (postToEdit: POST) => {
    try {
      const { id, ...postDataToSend } = postToEdit 
      await axios.put(`${baseApiUrl}/${id}`, postDataToSend);
      return postToEdit;
    } catch (err) {
      return null;
    }
  }
);
