import { useEffect, useState } from "react";

import Faq from "./Faq";
import { getFaqs } from "../../actions/faqs";
import { Department, FAQ } from "../../@types";
import { useAppDispatch, useAppSelector } from "../../config/hooks";

interface FaqsProps {
  department: Department;
}

function Faqs({ department }: FaqsProps) {
  const dispatch = useAppDispatch();
  const faqsStatus = useAppSelector((state) => state.faqs.status);
  const faqs = useAppSelector((state) => state.faqs.faqs);
  const [savedFaqs, setFaqs] = useState<Array<FAQ>>([]);
  useEffect(() => {
    if (faqsStatus === "idle") {
      dispatch(getFaqs());
    }
    if (faqsStatus === "succeeded") {
      setFaqs(faqs);
    }
  }, [faqs, faqsStatus, dispatch]);

  const departmentFaqs = savedFaqs
    .filter((p: FAQ) => p.tags.includes(department))
    .map((p: FAQ) => <Faq data={p} key={p.id} />);

  return (
    <div>
      <ul className="faqs__list">
        <header className="faqs__header">{department}</header>
        {departmentFaqs}
      </ul>
    </div>
  );
}

export default Faqs;
