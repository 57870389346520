import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  getYoutubeCategories,
  getPlaylistVideos,
  setMainVideo,
} from "../actions/youtube";
import { YoutubeCategory, YoutubeVideo } from "../@types";

interface YOUTUBE_INITIAL_STATE {
  categories: Array<YoutubeCategory>;
  mainVideo: YoutubeVideo;
  videos: Array<YoutubeVideo>;
  status: "idle" | "pending" | "succeeded" | "failed";
  error: any;
}

const initialState: YOUTUBE_INITIAL_STATE = {
  categories: [],
  mainVideo: {
    id: -1,
    video_description: "",
    video_title: "",
    video_id: "",
    category_id: "",
    thumbnail: "",
    date_published: "",
  },
  videos: [],
  status: "idle",
  error: null,
};

const youtubeSlice = createSlice({
  name: "youtube",
  initialState,
  reducers: {},

  extraReducers(builder: ActionReducerMapBuilder<YOUTUBE_INITIAL_STATE>) {
    builder.addCase(getYoutubeCategories.rejected, (_state, action) => ({
      ...initialState,
      status: "failed",
      error: action,
    }));
    builder.addCase(getYoutubeCategories.fulfilled, (state, action) => ({
      ...state,
      categories: action.payload.data,
      error: null,
      status: "succeeded",
    }));
    builder.addCase(getPlaylistVideos.fulfilled, (state, action) => {
      const videos = action.payload.videos;
      const mainVideo = videos[0];
      return {
        ...state,
        mainVideo,
        videos: videos.slice(1, videos.length),
        error: null,
        status: "succeeded",
      };
    });
    builder.addCase(setMainVideo.fulfilled, (state, action) => ({
      ...state,
      mainVideo: action.payload,
    }));
  },
});

export default youtubeSlice.reducer;
