import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";

import PostForm from "../../components/PostForm";
import RCTVSnackbar from "../../components/Snackbar";

import { createPosts } from "../../actions/posts";

import {
  ConfirmDataTypes,
  Department,
  ImageState,
  PostFormData,
  POST_REQUEST,
  SNACKBAR_STATUSES,
} from "../../@types";
import { DEFAULT_POST_IMAGE, INITIAL_POST_FORM } from "../../common/constants";
import checkIfSuperUser from "../../modules/checkIfSuperUser";
import formatDateForDjango from "../../modules/formatDateForDjango";
import getUserDepartment from "../../modules/getUserDepartment";
import uploadFilesToAws from "../../modules/uploadFilesToAws";
import { useAppDispatch } from "../../config/hooks";

import "./styles.scss";

function CreatePost() {
  const isSuperUser = checkIfSuperUser();
  const navigate = useNavigate();
  const [postData, updatePostData] = useState<PostFormData>(INITIAL_POST_FORM);
  const [snackbarOpen, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<SNACKBAR_STATUSES>(
    SNACKBAR_STATUSES.SUCCESS
  );
  const resetState = () => {
    updatePostData(INITIAL_POST_FORM);
  };
  const editorRef: any = useRef();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const onBackButtonEvent = (e: PopStateEvent) => {
      e.preventDefault();
      if (postData === INITIAL_POST_FORM) {
        if (
          window.confirm("Do you want to go back? All Progress will be lost")
        ) {
          // your logic
          navigate("/admin");
        } else {
          window.history.pushState(null, "", window.location.pathname);
        }
      }
    };
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [navigate, postData]);
  const handleConfirm = async (data: ConfirmDataTypes) => {
    const dataToDeconstruct = data as PostFormData;
    const {
      header_img: headerImg,
      format,
      body,
      pdf_img: pdfImg,
      video_link: videoLink,
      publish_date: publishDate,
      archived_date: archivedDate,
      body_img: bodyImg,
    } = dataToDeconstruct;
    let useDefaultImg = false;
    if (!headerImg) {
      useDefaultImg = true;

      setSnackbarMessage("Set your header image!");
      setSnackbarSeverity(SNACKBAR_STATUSES.ERROR);
      return setSnackbar(true);
    }
    let imagesToUpload: Array<ImageState> = [headerImg];
    if (bodyImg) {
      imagesToUpload = [headerImg, bodyImg];
    }
    if (pdfImg) {
      imagesToUpload = [headerImg, pdfImg];
    }
    const currentDate = new Date();
    let archivedDateToUse = archivedDate
      ? new Date(
          new Date(archivedDate).setMonth(new Date(archivedDate).getMonth() + 6)
        )
      : new Date(currentDate.setMonth(currentDate.getMonth() + 6));

    // get form data
    // upload image
    let bodyImgLink = DEFAULT_POST_IMAGE;
    let pdfImgLink = "";
    let rawImages = [];
    if (imagesToUpload.length > 0) {
      rawImages = (await uploadFilesToAws(imagesToUpload)) as any;
    }
    // get image location
    let headerImgLink = "";
    if (bodyImg) {
      const [headerImgLinkRes, bodyImgLinkRes] = await Promise.all(rawImages);
      headerImgLink = useDefaultImg ? DEFAULT_POST_IMAGE : headerImgLinkRes;
      bodyImgLink = useDefaultImg ? headerImgLinkRes : bodyImgLinkRes;
      delete dataToDeconstruct.body_img;
    } else if (pdfImg) {
      const [headerImgLinkRes, pdfImgLinkRes] = await Promise.all(rawImages);
      headerImgLink = useDefaultImg ? DEFAULT_POST_IMAGE : headerImgLinkRes;
      pdfImgLink = useDefaultImg ? headerImgLinkRes : pdfImgLinkRes;
      delete dataToDeconstruct.pdf_img;
    } else {
      const [headerImgLinkRes] = await Promise.all(rawImages);
      headerImgLink = useDefaultImg ? DEFAULT_POST_IMAGE : headerImgLinkRes;
    }
    let bodyToUse = body;
    if (format === "video") {
      bodyToUse = videoLink;
    }
    if (format === "image") {
      bodyToUse = bodyImgLink;
    }
    const userDepartment = getUserDepartment();
    const userDepartmentToSet = userDepartment ? [userDepartment] : ["All"];
    const tagsToUse = isSuperUser
      ? dataToDeconstruct.tags
      : userDepartmentToSet;
    const dataToSend: POST_REQUEST = {
      ...dataToDeconstruct,
      tags: tagsToUse as Array<Department>,
      header_img: headerImgLink,
      body: bodyToUse,
      video_link: videoLink,
      pdf_link: pdfImgLink,
      approval_date: formatDateForDjango(new Date("2055-02-03")),
      date_modified: formatDateForDjango(new Date()),
      date_created: formatDateForDjango(new Date()),
      publish_date: formatDateForDjango(new Date(publishDate)),
      archived_date: formatDateForDjango(new Date(archivedDateToUse)),
      ceo_post: false,
    };
    // submit to axios
    const res = await dispatch(createPosts(dataToSend));
    // get response
    let msg = "";
    if (res.meta.requestStatus === "rejected") {
      // set error
      msg +=
        "Unable to create new post! Please contact Jonathan for assistance";
      setSnackbarMessage(msg);
      setSnackbarSeverity(SNACKBAR_STATUSES.ERROR);
      return setSnackbar(true);
    }
    // show success
    msg += "Created new Post!";
    setSnackbarMessage(msg);
    setSnackbarSeverity(SNACKBAR_STATUSES.SUCCESS);
    setSnackbar(true);
    resetState();
  };
  return (
    <div id="post-page__container">
      <nav>
        <Link to="/admin">
          <article className="go-back">
            <HomeIcon />
            <h6>Go back to admin screen</h6>
          </article>
        </Link>
      </nav>
      <main>
        <h4>Create Post</h4>
        <PostForm
          editorRef={editorRef}
          isSuperUser={isSuperUser}
          postData={postData}
          handleConfirm={handleConfirm}
        />
        <RCTVSnackbar
          isOpen={snackbarOpen}
          severity={snackbarSeverity}
          setSnackbar={setSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </main>
    </div>
  );
}

export default CreatePost;
