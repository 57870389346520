import { useState } from "react";

import GoBackButton from "../../components/GoBackButton";
import RCTVSnackbar from "../../components/Snackbar";
import ProfileForm from "../../components/ProfileForm";

import { createProfile } from "../../actions/profiles";
import { useAppDispatch } from "../../config/hooks";
import {
  ConfirmDataTypes,
  ProfileFormData,
  CREATE_PROFILE_REQUEST,
  SNACKBAR_STATUSES,
} from "../../@types";
import { DEFAULT_DATE, DEPARTMENT_KEY, INITIAL_PROFILE_FORM } from "../../common/constants";
import formatDateForDjango from "../../modules/formatDateForDjango";
import uploadFilesToAws from "../../modules/uploadFilesToAws";

function CreateProfile() {
  const userDepartmentToSet = localStorage.getItem(DEPARTMENT_KEY);
  const [snackbarOpen, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<SNACKBAR_STATUSES>(
    SNACKBAR_STATUSES.SUCCESS
  );

  const dispatch = useAppDispatch();

  const handleConfirm = async (data: ConfirmDataTypes) => {
    const content = data as ProfileFormData;
    const { employee_name, position, email, department, imageToSet } = content;
    let profileImageLink = ""
    if (imageToSet && imageToSet.src.length > 0) {
      const rawImages = (await uploadFilesToAws([imageToSet])) as any
      const [profileImage] = await Promise.all(rawImages)
      profileImageLink = profileImage
    }
    const dataToSend: CREATE_PROFILE_REQUEST = {
      employee_name,
      position,
      email,
      image: profileImageLink,
      department: userDepartmentToSet ? userDepartmentToSet : department,
      date_modified: formatDateForDjango(new Date()),
      date_created: formatDateForDjango(new Date()),
      approval_date: DEFAULT_DATE,
      approved_by: "",
    };
    const res = await dispatch(createProfile(dataToSend));

    let msg = "";
    if (res.meta.requestStatus === "rejected") {
      msg +=
        "Unable to create new Profile! Please contact Jonathan for assistance";
      setSnackbarMessage(msg);
      setSnackbarSeverity(SNACKBAR_STATUSES.ERROR);
      setSnackbar(true);
      return;
    }

    msg += "Created new Profile!";
    setSnackbarMessage(msg);
    setSnackbarSeverity(SNACKBAR_STATUSES.SUCCESS);
    setSnackbar(true);
    return;
  };

  const formData: ProfileFormData = INITIAL_PROFILE_FORM;

  return (
    <div id="post-page__container">
      <GoBackButton link={"/"} />
      <main>
        <header>
          <h4>Create Profile</h4>
          <ProfileForm
            formData={formData}
            handleConfirm={handleConfirm}
            isSuperUser={false}
            defaultDepartment={userDepartmentToSet}
          />
        </header>
        <RCTVSnackbar
          isOpen={snackbarOpen}
          severity={snackbarSeverity}
          setSnackbar={setSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </main>
    </div>
  );
}

export default CreateProfile;
