import { Department, POST, RCTVDate } from "../@types";

function handlePostFilter(
  p: POST,
  showCEOPosts: boolean,
  department: Department
) {
  const inDepartment = department === "All" || p.tags.includes(department);
  const isApproved =
    p.approval_date &&
    new Date(p.approval_date) <= new Date() &&
    p.approved_by !== "";
  const isCEOPost = p.ceo_post;
  const archiveDate = p.archived_date
    ? p.archived_date
    : new RCTVDate().addDays(365);
  const isBeforeArchivedDate = new Date() < new Date(archiveDate);
  const isAfterPublishDate = new Date() >= new Date(p.publish_date);
  if (showCEOPosts) {
    return (
      isApproved &&
      inDepartment &&
      isCEOPost &&
      isBeforeArchivedDate &&
      isAfterPublishDate
    );
  }
  return (
    isApproved && inDepartment && isAfterPublishDate && isBeforeArchivedDate
  );
}

export default handlePostFilter;
