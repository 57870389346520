import { Link } from "react-router-dom";

import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";

import FinanceImg from "../../assets/images/department-images-accounting.jpg";
import CallcenterImg from "../../assets/images/department-images-call-center.jpg";
import ItImg from "../../assets/images/department-images-it.jpg";
import MarketingImg from "../../assets/images/department-images-marketing.jpg";
import PactImg from "../../assets/images/department-images-PACT.jpg";
import PostProductionImg from "../../assets/images/department-images-post-production.jpg";
import ProductionImg from "../../assets/images/department-images-production.jpg";
import ShippingImg from "../../assets/images/department-images-shipping.jpg";

import "./styles.scss";

function Departments() {
  return (
    <div className="App">
      <Sidebar />
      <main>
        <Header />
        <div className="department__container">
          <header className="department__header">Departments</header>
          <section className="department__outer-wrapper">
            <article className="department__wrapper">
              <img src={PactImg} alt="background for pact" />
              <div className="vignette" />
              <Link to="/departments/pact">
                <sub>PACT</sub>
              </Link>
            </article>
            <article className="department__wrapper">
              <img src={FinanceImg} alt="background for pact" />
              <div className="vignette" />
              <Link to="/departments/finance">
                <sub>Finance</sub>
              </Link>
            </article>
            <article className="department__wrapper">
              <img src={MarketingImg} alt="background for marketing" />
              <div className="vignette" />
              <Link to="/departments/marketing">
                <sub>Marketing</sub>
              </Link>
            </article>
            <article className="department__wrapper">
              <img src={ItImg} alt="background for it" />
              <div className="vignette" />
              <Link to="/departments/it">
                <sub>IT</sub>
              </Link>
            </article>
            <article className="department__wrapper">
              <img src={CallcenterImg} alt="background for it" />
              <div className="vignette" />
              <Link to="/departments/callcenter">
                <sub>Call Center/Customer Service</sub>
              </Link>
            </article>
            <article className="department__wrapper">
              <img src={ShippingImg} alt="background for it" />
              <div className="vignette" />
              <Link to="/departments/cointeam">
                <sub>Shipping/Coin Team</sub>
              </Link>
            </article>
            <article className="department__wrapper">
              <img src={ProductionImg} alt="background for it" />
              <div className="vignette" />
              <Link to="/departments/production">
                <sub>Production Team</sub>
              </Link>
            </article>
            <article className="department__wrapper">
              <img src={PostProductionImg} alt="background for it" />
              <div className="vignette" />
              <Link to="/departments/post-production">
                <sub>Post-Production Team</sub>
              </Link>
            </article>
          </section>
        </div>
      </main>
    </div>
  );
}

export default Departments;
