import { Link } from "react-router-dom";

import { POST } from "../../@types";
import DefaultImg from "../../assets/images/No_Image_Available.jpg";
import { DEPARTMENT_COLORS } from "../../common/constants";

interface PostProps {
  data: POST;
}

function Post({ data }: PostProps) {
  const {
    subtitle,
    tags,
    title,
    header_img: headerImg,
    publish_date,
    id,
  } = data;
  const imgToUse = headerImg !== "" ? headerImg : DefaultImg;
  const dateToShow = new Date(publish_date).toLocaleString("en-us", {
    month: "short",
    year: "numeric",
    day: "numeric",
  });
  const departmentsToShow =
    tags.length > 0 ? (
      tags.map((t) => {
        const departmentColor = DEPARTMENT_COLORS.find(
          (dc) => dc.departmentName === t
        );
        return (
          <span
            className="department-tag__text"
            style={{
              color: departmentColor?.textColor,
              backgroundColor: departmentColor?.bgColor,
            }}
          >
            {t}
          </span>
        );
      })
    ) : (
      <span
        className="department-tag__text"
        style={{
          color: DEPARTMENT_COLORS[0].textColor,
          backgroundColor: DEPARTMENT_COLORS[0].bgColor,
        }}
      >
        All
      </span>
    );
  return (
    <li className="post__container">
      <Link className="post-link__container" to={`/post/${id}`}>
        <img className="post__image" src={imgToUse} alt={title} />
        <article className="post-title__container">
          <article className="department-tag__container">
            {departmentsToShow}
          </article>
          <h4>{title}</h4>
          <h6>Published on {dateToShow}</h6>
          <h5>{subtitle}</h5>
        </article>
        <article className="post-title__spacer" />
      </Link>
    </li>
  );
}

export default Post;
