import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import HomeIcon from "@mui/icons-material/Home";
import FaceIcon from "@mui/icons-material/Face";
import TheatersIcon from "@mui/icons-material/Theaters";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LoginIcon from "@mui/icons-material/Login";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

import Avatar from "@mui/material/Avatar";

const iconSx = { fontSize: 24 };
const { pathname } = window.location;

function Sidebar() {
  const [activePath, setPath] = useState<string>(pathname);
  useEffect(() => {
    const { pathname } = window.location;
    setPath(pathname);
  }, []);
  const menu = [
    {
      icon: <HomeIcon sx={iconSx} />,
      text: "Home",
      activePath: "/",
    },
    {
      icon: <FaceIcon sx={iconSx} />,
      text: "Human Resources",
      activePath: "/humanresources",
    },
    {
      icon: <TheatersIcon sx={iconSx} />,
      text: "Videos",
      activePath: "/videos",
    },
    {
      icon: <CalendarMonthIcon sx={iconSx} />,
      text: "Events",
      activePath: "/events",
    },
    {
      icon: <BusinessCenterIcon sx={iconSx} />,
      text: "Departments",
      activePath: "/departments",
    },
    {
      icon: <LoginIcon sx={iconSx} />,
      text: "Admin Portal",
      activePath: "/admin",
    },
  ].map((mItem) => {
    const isHome = window.location.pathname === "/";
    let className = activePath.includes(mItem.activePath)
      ? "menu__item active"
      : "menu__item";
    if (!isHome && mItem.text === "Home") {
      className = "menu__item";
    }
    return (
      <ListItem className={className} key={mItem.text}>
        <article className="menu__item-content">
          <Link className="sidebar__link" to={mItem.activePath}>
            <ListItemAvatar>
              <Avatar>{mItem.icon}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={mItem.text} />
          </Link>
        </article>
      </ListItem>
    );
  });
  return (
    <section className="menu__container">
      <List className="menu__list">{menu}</List>
      <div className="social-outlinks">
        <a
          href="https://www.instagram.com/rarecollectiblestv/?hl=en"
          target="_blank"
        >
          <InstagramIcon className="animation-icon" />
        </a>

        <a href="https://www.facebook.com/rarecollectiblestv/" target="_blank">
          <FacebookIcon className="animation-icon" />
        </a>

        <a href="https://twitter.com/rarecointv?lang=en" target="_blank">
          <TwitterIcon className="animation-icon" />
        </a>
      </div>
    </section>
  );
}

export default Sidebar;
