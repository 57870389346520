import { motion, Variants } from "framer-motion";
import { HR_FILE } from "../../../@types";

interface FileProps {
  file: HR_FILE;
  i: number;
}

function File({ file, i }: FileProps) {
  const listVariants: Variants = {
    animate: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.3, delay: i * 0.1 },
    },
    initial: { opacity: 0, y: -50 },
  };
  return (
    <motion.li
      whileHover={{ scale: 1.02, color: "#68797b" }}
      whileTap={{ scale: 1.3 }}
      className="hr-file"
      variants={listVariants}
      key={file.filename}
      animate="animate"
      initial="initial"
      custom={i}
    >
      <a href={file.filePath} download={file.filename}>
        {file.filename}
      </a>
    </motion.li>
  );
}

export default File;
