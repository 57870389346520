import { useEffect } from "react";

import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import Profiles from "../../../components/Profiles";
import BackButton from "../../../components/BackButton";

import MakeProfileButton from "../../../components/Profiles/ProfileButton";

import { clearProfiles } from "../../../actions/profiles";
import { useAppDispatch } from "../../../config/hooks";

const DEPARTMENT = "Post Production";

function PostProductionTeam() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearProfiles());
  }, [dispatch]);
  return (
    <div className="App">
      <Sidebar />
      <main>
        <Header />
        <div className="posts__container">
          <section className="team__wrapper">
            <header className="posts__header__title">
              <article className="department__name__styling">
                <BackButton />
                <h4>Meet The Team:</h4>
              </article>
              <article className="button-position">
                <MakeProfileButton department={DEPARTMENT} />
              </article>
            </header>
            <article className="department-name-container">
              {DEPARTMENT}
            </article>
            <article className="team__bio">
              <Profiles department={DEPARTMENT} />
            </article>
          </section>
        </div>
      </main>
    </div>
  );
}

export default PostProductionTeam;
