import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import {
  HR_SECTION,
  HR_SUBSECTION_BLOCK,
  NewAnimatePresenceProps,
} from "../../../@types";
import { SUBSECTIONS } from "../../../common/constants";

import FileSubsection from "./FileSubsection";

interface FileSectionProps {
  sectionName: HR_SECTION;
}

function FileSection({ sectionName }: FileSectionProps) {
  const [showSubsections, toggleSubsections] = useState<boolean>(false);
  const NewAnimatePresence: React.FC<NewAnimatePresenceProps> = AnimatePresence;
  const spanContent = showSubsections ? "-" : "+";
  const handleSpanClick = () => toggleSubsections(!showSubsections);
  const subsections = SUBSECTIONS.filter(
    (subsection) => subsection.parent === sectionName
  ).map((s: HR_SUBSECTION_BLOCK) => (
    <FileSubsection subsection={s} key={s.name} />
  ));
  return (
    <section>
      <article className="hr-file-section__container">
        <motion.span
          whileHover={{ scale: 1.02, color: "#c09243" }}
          whileTap={{ scale: 1.04, color: "#c09243" }}
          onClick={handleSpanClick}
        >
          {spanContent}
        </motion.span>
        <article className="hr-file__label">{sectionName}</article>
      </article>
      <NewAnimatePresence>{showSubsections && subsections}</NewAnimatePresence>
    </section>
  );
}

export default FileSection;
