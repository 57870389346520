import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  adminUsers,
  ADMIN_KEY,
  ADMIN_USER_KEY,
  SUPER_USER_KEY,
} from "../common/constants";
import { AdminUser } from "../@types";

export const FALSE = "false";
export const TRUE = "true";

interface SignInCredentials extends Omit<AdminUser, "department"> {}
export const SIGN_IN = "SIGN_IN";
export const signIn = createAsyncThunk(
  SIGN_IN,
  async ({ username, password }: SignInCredentials) => {
    const authenticatedUser = adminUsers.find(
      (adminUser: AdminUser) =>
        adminUser.username.toLocaleLowerCase() ===
          username.toLocaleLowerCase() &&
        password.toLocaleLowerCase() === adminUser.password.toLocaleLowerCase()
    );
    const isFound: Boolean =
      authenticatedUser !== undefined
    if (isFound && authenticatedUser) {
      localStorage.setItem(ADMIN_KEY, TRUE);
      localStorage.setItem(ADMIN_USER_KEY, username);
      const isSuperUser =
        authenticatedUser.department === "All" ||
        authenticatedUser.department === "Human Resources";
      const superUserVal = isSuperUser ? TRUE : FALSE;
      localStorage.setItem(SUPER_USER_KEY, superUserVal);
    } else {
      localStorage.setItem(ADMIN_KEY, FALSE);
    }
    return isFound;
  }
);

export const SIGN_OUT = "SIGN_OUT";
export const signOut = createAsyncThunk(SIGN_OUT, async () => {
  localStorage.setItem(ADMIN_KEY, FALSE);
  localStorage.removeItem(SUPER_USER_KEY)
  localStorage.removeItem(ADMIN_USER_KEY)
  return true;
});
