import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { AdminItemType } from "../../@types";

interface DeleteConfirmModalProps {
  isOpen: boolean;
  handleClose: Function;
  handleDeleteConfirm: Function;
  itemType: AdminItemType
}

function DeleteConfirmModal({
  isOpen,
  handleDeleteConfirm,
  handleClose,
  itemType,
}: DeleteConfirmModalProps) {
  const handleDelete = async () => handleDeleteConfirm(itemType);

  const handleDeleteClick = () => handleDelete();
  const handleCloseClick = () => handleClose();

  return (
    <Modal open={isOpen}>
      <Box>
        <div className="modal__container" id="delete-modal">
          <header className="modal__header">Delete Confirmation</header>
          <section className="modal__summary">
            <article className="modal__instructions">
              <summary>
                Are you sure you want to delete this {itemType}? This action is
                irreversible
              </summary>
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
            </article>
          </section>
          <footer>
            <Button
              variant="text"
              color="info"
              style={{ color: "grey" }}
              onClick={handleCloseClick}
            >
              Close
            </Button>
          </footer>
        </div>
      </Box>
    </Modal>
  );
}

export default DeleteConfirmModal;
