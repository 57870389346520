import { useEffect } from "react";
import { Link } from "react-router-dom";

import Posts from "../../../components/Posts";
import Faqs from "../../../components/Faqs";
import Sidebar from "../../../components/Sidebar";

import logo from "../../../assets/images/rctv-logo.png";

import { useAppDispatch } from "../../../config/hooks";

import { clearPosts } from "../../../actions/posts";
import { getWeather } from "../../../actions/weather";

const DEPARTMENT = "Post Production";

function PostProductionHome() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getWeather());
    dispatch(clearPosts());
  });

  return (
    <div className="App">
      <Sidebar />
      <main>
        <section style={{ marginBottom: 50 }}>
          <header>
            <img src={logo} alt="Rare Collectibles logo" />
          </header>
        </section>
        <section className="posts__container">
          <section className="posts__wrapper">
            <Posts isHomePage={false} department="Post Production" />
          </section>
          <aside>
            <article>
              <header>Post-Prouction Team</header>
              <ul>
                <li className="useful-links__list-item">
                  <Link to="/departments/postproduction/postproductionteam">
                    Meet the team
                  </Link>
                </li>
              </ul>
            </article>
            <article>
              <header> FAQ:</header>
              <Faqs department={DEPARTMENT} />
            </article>
          </aside>
        </section>
      </main>
    </div>
  );
}

export default PostProductionHome;
