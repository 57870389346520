import { PROFILE } from "../../@types";
import ProfilePic from "../../assets/images/profile-pic.png";

interface ProfileProps {
  data: PROFILE;
}

function Profile({ data }: ProfileProps) {
  const { employee_name, image, position, email, department } = data;

  return (
    <div className="posts__container__profiles">
      <section className="team__wrapper">
        <article>
          <header className="team__header"></header>
          <ul className="team__list">
            <li className="useful-links__list-item__profiles">
              <figure className="team-member">
                <img src={image} alt="template" />
                <article>
                  <sup className="team-member-detail team-member--name">
                    {employee_name}
                  </sup>
                  <div className="dept-title-row">
                    <article className="department__list__profiles">
                      {department} -
                    </article>
                    <sub className="team-member-detail team-member--title">
                      {position}
                    </sub>
                  </div>
                  <sub className="team-member-detail team-member--email">
                    {email}
                  </sub>

                  <hr />
                </article>
              </figure>
            </li>
          </ul>
        </article>
      </section>
    </div>
  );
}

export default Profile;
