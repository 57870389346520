import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apiUrl from "../constants/apiUrl";
import { CREATE_PROFILE_REQUEST, UPDATE_PROFILE_REQUEST } from "../@types";

const baseApiUrl = `${apiUrl}/profiles`;

export const GET_PROFILES = "GET_PROFILES";
export const getProfiles = createAsyncThunk(GET_PROFILES, async () => {
  const res: AxiosResponse = await axios.get(baseApiUrl);
  return res.data;
});

export const CREATE_PROFILE = "CREATE_PROFILE";
export const createProfile = createAsyncThunk(
  CREATE_PROFILE,
  async (profiledata: CREATE_PROFILE_REQUEST) => {
    try {
      const res: AxiosResponse = await axios.post(baseApiUrl, profiledata);
      return res.data.created_profile;
    } catch (err) {
      return err;
    }
  }
);
export const DELETE_PROFILE = "DELETE_PROFILE";
export const deleteProfile = createAsyncThunk(
  DELETE_PROFILE,
  async (profileId: number | string) => {
    try {
      await axios.delete(`${baseApiUrl}/${profileId}`);
      return profileId;
    } catch (err) {
      return false;
    }
  }
);

export const clearProfiles = createAsyncThunk(
  "CLEAR_PROFILES",
  async () => null
);

export const GET_PROFILE = "GET_PROFILE";
export const getProfile = createAsyncThunk(
  GET_PROFILE,
  async (id: string | number) => {
    const res: AxiosResponse = await axios.get(`${baseApiUrl}/${id}`);
    return res.data;
  }
);

export const EDIT_PROFILE = "EDIT_PROFILE";
export const editProfile = createAsyncThunk(
  EDIT_PROFILE,
  async (profileToEdit: UPDATE_PROFILE_REQUEST) => {
    try {
      const { id, ...profileBody } = profileToEdit;
      await axios.put(`${baseApiUrl}/${id}`, profileBody);
      return profileBody;
    } catch (err) {
      return null;
    }
  }
);
