import { motion, Variants } from "framer-motion";

import YTCategory from "../../components/YoutubeCategory";

import { useAppSelector } from "../../config/hooks";

interface CategoryListProps {
  handleCategoryClick: Function;
}

function CategoryList({ handleCategoryClick }: CategoryListProps) {
  const variants: Variants = {
    visible: {
      opacity: 1,
      transition: { delayChildren: 0.2, staggerChildren: 0.1 },
    },
    hidden: { opacity: 0 },
  };
  const { categories } = useAppSelector((state) => state.youtube);
  return (
    <motion.section
      variants={variants}
      animate="visible"
      initial="hidden"
      className="youtube-category__playlist-container"
    >
      <header className="youtube-category__playlist-section-header">
        Video Categories
      </header>
      {categories.map((cat, index) => (
        <YTCategory
          index={index}
          handleClick={handleCategoryClick}
          cat={cat}
          key={cat.id}
        />
      ))}
    </motion.section>
  );
}

export default CategoryList;
