import { useEffect, useState } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import AdminFaq from "./AdminFaq";

import { getFaqs } from "../../actions/faqs";
import { FAQ } from "../../@types";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { DEPARTMENTS } from "../../common/constants";

import "./styles.scss"

interface AdminFaqsProps {
  editMode: boolean;
  openDeleteModal: Function;
}

function AdminFaqs({ editMode, openDeleteModal }: AdminFaqsProps) {
  const dispatch = useAppDispatch();
  const faqsStatus = useAppSelector((state) => state.faqs.status);
  const faqs = useAppSelector((state) => state.faqs.faqs);
  const [savedFaqs, setFaqs] = useState<Array<FAQ>>([]);
  const [departmentToShow, setDepartmentToShow] = useState<string>("");
  useEffect(() => {
    if (faqsStatus === "idle") {
      dispatch(getFaqs());
    }
    if (faqsStatus === "succeeded") {
      setFaqs(faqs);
    }
  }, [faqs, faqsStatus, dispatch]);

  const faqsToRender =
    savedFaqs &&
    savedFaqs.length > 0 &&
    savedFaqs
      .filter((p: FAQ) => p && p.tags.includes(departmentToShow))
      .map((p: FAQ) => (
        <AdminFaq
          openDeleteModal={openDeleteModal}
          editMode={editMode}
          data={p}
          key={p.id}
        />
      ));
  const handleChange = (e: SelectChangeEvent) =>
    setDepartmentToShow(e.target.value);
  const departmentOptions = DEPARTMENTS.map((dept) => (
    <MenuItem key={dept} value={dept}>
      {dept}
    </MenuItem>
  ));
  return (
    <div>
      <section className="faq__instructions">
        <header>Select department to show</header>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="department-select-small">Department</InputLabel>
          <Select
            labelId="department-select-small"
            id="department-select-small"
            value={departmentToShow}
            label="Department"
            onChange={handleChange}
          >
            {departmentOptions}
          </Select>
        </FormControl>
      </section>
      <section className="faq-list__container">{faqsToRender}</section>
    </div>
  );
}

export default AdminFaqs;
