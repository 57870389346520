import { useEffect, useState } from "react";

import CategoryList from "./CategoryList";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import YoutubePlaylist from "../../components/YoutubePlaylist";

import { useAppDispatch } from "../../config/hooks";
import { getYoutubeCategories } from "../../actions/youtube";

import "./styles.scss";

function Videos() {
  const [categoryId, setCategoryId] = useState<string>("");
  const [categoryName, setCategoryName] = useState<string>("");
  const handleCategoryClick = (catId: string, catName: string) => {
    setCategoryId(catId);
    setCategoryName(catName);
  };
  const clearCategory = () => {
    setCategoryId("");
    return setCategoryName("");
  };
  const dispatch = useAppDispatch();
  const content = () => {
    if (!categoryId) {
      return <CategoryList handleCategoryClick={handleCategoryClick} />;
    }
    return (
      <YoutubePlaylist
        handleClearCategory={clearCategory}
        playlistName={categoryName}
        playlistId={categoryId}
      />
    );
  };
  useEffect(() => {
    dispatch(getYoutubeCategories());
  }, [dispatch]);
  return (
    <div className="App">
      <Sidebar />
      <main>
        <Header />
        <div className="youtube__container">{content()}</div>
      </main>
    </div>
  );
}

export default Videos;
