import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  clearFaqs,
  createFaqs,
  deleteFaq,
  editFaq,
  getFaqs,
} from "../actions/faqs";
import { FAQ } from "../@types";

interface FAQS_INITIAL_STATE {
  faqs: Array<FAQ>;
  status: "idle" | "pending" | "succeeded" | "failed";
  error: any;
}

const initialState: FAQS_INITIAL_STATE = {
  faqs: [],
  status: "idle",
  error: null,
};

const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {},

  extraReducers(builder: ActionReducerMapBuilder<FAQS_INITIAL_STATE>) {
    builder.addCase(getFaqs.rejected, (_state, action) => ({
      ...initialState,
      status: "failed",
      error: action,
    }));
    builder.addCase(getFaqs.fulfilled, (_state, action) => ({
      faqs: action.payload.data,
      error: null,
      status: "succeeded",
    }));
    builder.addCase(createFaqs.fulfilled, (state, action) => {
      const { post } = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.faqs));
      newArr.push(post);
      state.faqs = newArr;
    });
    builder.addCase(deleteFaq.fulfilled, (state, action) => {
      const postId = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.faqs));
      const postIdx = state.faqs.find((p) => p.id === postId);
      newArr.splice(postIdx, 1);
      state.faqs = newArr;
    });

    builder.addCase(clearFaqs.fulfilled, () => ({ ...initialState }));

    builder.addCase(editFaq.fulfilled, (state, action) => {
      const faqToEdit: FAQ = action.payload as unknown as FAQ;
      const newArr = JSON.parse(
        JSON.stringify(state.faqs)
      ) as unknown as Array<FAQ>;
      const postIdx = state.faqs.findIndex((p) => p.id === faqToEdit.id);
      newArr[postIdx] = faqToEdit;
      state.faqs = newArr;
    });
  },
});

export default faqsSlice.reducer;
