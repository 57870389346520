import { adminUsers, ADMIN_USER_KEY } from "../common/constants";
import { Department } from "../@types";

function getUserDepartment(): Department | undefined {
  const adminUser = localStorage.getItem(ADMIN_USER_KEY);
  if (adminUser) {
    const foundUser = adminUsers.find(
      (admUser) => admUser.username === adminUser
    );
    if (foundUser) {
      return foundUser.department;
    }
  }
  return undefined;
}
export default getUserDepartment;
