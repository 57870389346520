import React, { useState } from "react";

import { RRule, WeekdayStr } from "rrule";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SelectChangeEvent } from "@mui/material";

import { EventFormData, FREQUENCY_OPTION, RCTVDate } from "../../@types";
import {
  DEPARTMENTS,
  RCTVMenuProps,
  RRULE_DAY_HASHMAP,
} from "../../common/constants";
import toTitleCase from "../../modules/titleCase";

interface FormProps {
  formData: EventFormData;
  isSuperUser: boolean;
  updateState: Function;
}

const repeatContainerStyles = {
  display: "flex",
  alignItems: "center",
  flexBasis: "100%",
  width: 0,
  margin: "14px 0",
};

function Form({ formData, isSuperUser, updateState }: FormProps) {
  const {
    title,
    event_description,
    start_date,
    end_date,
    tags,
    end_time,
    start_time,
    rrulestr: rrulestrToParse,
    rrule,
  } = formData;
  let rruleStrText = "";
  const val = tags.length > 0 ? tags : [];
  let initialFrequency = FREQUENCY_OPTION.NONE;
  if (rrule) {
    rruleStrText = rrule.toText();
  }
  if (rrulestrToParse && rrulestrToParse.length > 0) {
    const frequencyRegex = /(?==).*?(?=;INTERVAL)/;
    const frequencyMatchArr: Array<string> = rrulestrToParse.match(
      frequencyRegex
    ) as Array<string>;
    let strFrequency = frequencyMatchArr.length > 0 ? frequencyMatchArr[0] : "";
    if (strFrequency.length > 0) {
      const optionToChoose = strFrequency.substring(
        1
      ) as keyof typeof FREQUENCY_OPTION;
      initialFrequency = FREQUENCY_OPTION[optionToChoose];
    }
  }
  const [newTags, setNewTags] = useState<Array<string>>(val);
  const [frequencyOption, setFrequencyOption] =
    useState<FREQUENCY_OPTION>(initialFrequency);
  const REPEAT_OPTIONS: Array<FREQUENCY_OPTION> = [
    FREQUENCY_OPTION.DAILY,
    FREQUENCY_OPTION.WEEKLY,
    FREQUENCY_OPTION.BIWEEKLY,
    FREQUENCY_OPTION.MONTHLY,
    FREQUENCY_OPTION.YEARLY,
  ];
  const updateFrequency = (initialRo?: FREQUENCY_OPTION) => {
    const ro = initialRo || frequencyOption;
    const dateObj = new Date(start_date || new Date());
    const month = dateObj.getUTCMonth();
    const utcDay = dateObj.getUTCDate() + 1;
    const utcYear = dateObj.getUTCFullYear();
    const day = dateObj.toLocaleDateString("en-us", { weekday: "short" });
    const rruleDay = RRULE_DAY_HASHMAP[day] as unknown as WeekdayStr;
    const rruleData: any = {
      dtstart: new Date(Date.UTC(utcYear, month, utcDay)),
      until: new RCTVDate().addDays(364),
    };
    if (ro !== FREQUENCY_OPTION.NONE) {
      let freq = RRule.DAILY;
      let newRRule = new RRule({
        freq,
        ...rruleData,
      });
      if (ro !== FREQUENCY_OPTION.DAILY) {
        rruleData.byweekday = [RRule[rruleDay]];
      }
      if (ro === FREQUENCY_OPTION.MONTHLY) {
        rruleData.interval = 1;
        rruleData.bymonthday = [utcDay - 1];
        delete rruleData.byweekday;
      }
      if (ro === FREQUENCY_OPTION.BIWEEKLY) {
        newRRule = new RRule({
          freq: RRule.WEEKLY,
          interval: 2,
          ...rruleData,
        });
      } else {
        freq = RRule[ro];
        newRRule = new RRule({
          freq,
          ...rruleData,
        });
      }
      return updateState(newRRule, "rrule");
    }
  };
  const handleTitleInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateState(e.target.value, "title");
  const handleDescriptionInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateState(e.target.value, "description");
  const handleStartDateChange = (newValue: Date | null) => {
    if (frequencyOption !== FREQUENCY_OPTION.NONE) {
      updateFrequency();
    }
    updateState(newValue, "start_date");
  };
  const handleEndDateChange = (newValue: Date | null) =>
    updateState(newValue, "end_date");
  const handleTagsInput = (e: SelectChangeEvent<typeof newTags>) => {
    const {
      target: { value },
    } = e;
    const newValue = typeof value === "string" ? value.split(",") : value;
    setNewTags(newValue);
    updateState(value, "tags");
  };
  const handleStartTimeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (frequencyOption !== FREQUENCY_OPTION.NONE) {
      updateFrequency();
    }
    updateState(event.target.value, "start_time");
  };
  const handleEndTimeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => updateState(event.target.value, "end_time");
  const onRepeatOptionSelect = (event: SelectChangeEvent) => {
    const ro = event.target.value as string as FREQUENCY_OPTION;
    setFrequencyOption(ro);
    updateFrequency(ro);
  };
  const repeatOptions = REPEAT_OPTIONS.map((ro) => {
    return (
      <MenuItem key={ro} value={ro}>
        {toTitleCase(ro)}
      </MenuItem>
    );
  });
  const showEndTime = frequencyOption === FREQUENCY_OPTION.NONE;
  return (
    <form className="events__form">
      <TextField label="Title" value={title} onChange={handleTitleInput} />
      <TextField
        label="Description"
        multiline
        value={event_description}
        onChange={handleDescriptionInput}
        variant="filled"
      />
      {isSuperUser && (
        <FormControl>
          <Select
            multiple
            value={newTags}
            input={<OutlinedInput />}
            MenuProps={RCTVMenuProps}
            onChange={handleTagsInput}
          >
            {DEPARTMENTS.map((dept) => (
              <MenuItem key={dept} value={dept}>
                {dept}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <article className="date__row">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            inputFormat="MM/dd/yyyy"
            value={start_date}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <TextField
          id="start-time"
          label="Start Time"
          onChange={handleStartTimeChange}
          value={start_time}
          type="time"
          defaultValue="00:00"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          sx={{ width: 150 }}
        />

        {isSuperUser && (
          <article className="repeat__container" style={repeatContainerStyles}>
            <FormControl>
              <Select
                value={frequencyOption}
                onChange={onRepeatOptionSelect}
                labelId="recurring-rules"
              >
                <MenuItem value={FREQUENCY_OPTION.NONE}>
                  Does not repeat
                </MenuItem>
                {repeatOptions}
              </Select>
            </FormControl>
            <span className="rrule-details">{rruleStrText}</span>
          </article>
        )}
        {showEndTime && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="End Date"
              inputFormat="MM/dd/yyyy"
              value={end_date}
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <TextField
              id="end-time"
              label="End Time"
              onChange={handleEndTimeChange}
              type="time"
              defaultValue="00:00"
              value={end_time}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              sx={{ width: 150 }}
            />
          </LocalizationProvider>
        )}
      </article>
    </form>
  );
}

export default Form;
