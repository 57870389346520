import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";

import FaqForm from "../../components/FaqForm";
import RCTVSnackbar from "../../components/Snackbar";

import { editFaq, getFaq } from "../../actions/faqs";

import {
  Department,
  IPostFormFaq,
  FAQ,
  FAQ_REQUEST,
  SNACKBAR_STATUSES,
} from "../../@types";
import { useAppDispatch } from "../../config/hooks";

import "./styles.scss";

interface EditFaqParams {
  id?: string | number;
}

function EditFaq() {
  const { id }: EditFaqParams = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const goToAdminPage = () => navigate(`/admin`)
  const [question, setQuestion] = useState<string>("");
  const [department, setDepartment] = useState<Department>("All");
  const [answer, setAnswer] = useState<string>("");
  const [snackbarOpen, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<SNACKBAR_STATUSES>(
    SNACKBAR_STATUSES.SUCCESS
  );
  useEffect(() => {
    async function getFaqFromParams(id: string | number) {
      const { payload } = await dispatch(getFaq(id));
      const retrievedFaq: FAQ = payload.data[0] as FAQ;
      const { question, department, answer } = retrievedFaq;
      setQuestion(question);
      setDepartment(department);
      setAnswer(answer);
    }
    if (id) {
      getFaqFromParams(id);
    }
  }, [dispatch, id]);
  const resetState = () => {
    setQuestion("");
    setDepartment("All");
    setAnswer("");
  };
  const updateState = (
    value: boolean | Array<string> | string,
    key: string
  ) => {
    let valToSet: boolean | Array<string> | string = value as string;
    if (key === "question") {
      return setQuestion(valToSet);
    }
    if (key === "department") {
      return setDepartment(valToSet as Department);
    }
    if (key === "answer") {
      return setAnswer(valToSet);
    }
  };
  const handleConfirm = async () => {
    const dataToSend: FAQ_REQUEST = {
      id,
      question: question,
      answer: answer,
      department: department,
    };
    // submit to axios
    const res = await dispatch(editFaq(dataToSend));
    // get response
    let msg: string = "";
    if (res.meta.requestStatus === "rejected") {
      // set error
      msg += "Unable to create new FAQ! Please contact Jonathan for assistance";
      setSnackbarMessage(msg);
      setSnackbarSeverity(SNACKBAR_STATUSES.ERROR);
      return setSnackbar(true);
    }
    // show success
    msg += "Edited FAQ!";
    setSnackbarMessage(msg);
    setSnackbarSeverity(SNACKBAR_STATUSES.SUCCESS);
    setSnackbar(true);
    goToAdminPage()
    resetState();
  };
  const formData: IPostFormFaq = {
    question,
    department,
    answer,
  };
  const content = <FaqForm updateState={updateState} formData={formData} />;
  return (
    <div id="post-page__container">
      <nav>
        <Link to="/admin">
          <article className="go-back">
            <HomeIcon />
            <h6>Go back to admin screen</h6>
          </article>
        </Link>
      </nav>
      <main>
        <header>
          <h4>Edit FAQ</h4>
        </header>
        {content}
        <article>
          <Button onClick={handleConfirm} color="success" variant="contained">
            Save FAQ
          </Button>
        </article>
        <RCTVSnackbar
          isOpen={snackbarOpen}
          severity={snackbarSeverity}
          setSnackbar={setSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </main>
    </div>
  );
}

export default EditFaq;
