import { motion, AnimatePresenceProps, AnimatePresence } from "framer-motion";
import { useState } from "react";

import { HR_FILE, HR_SUBSECTION_BLOCK } from "../../../@types";
import { HR_FILES } from "../../../common/constants";
import { ulVariants } from "./animations";
import File from "./File";

interface NewAnimatePresenceProps
  extends Omit<AnimatePresenceProps, "children"> {
  children: React.ReactNode;
}

interface FileSubsectionProps {
  subsection: HR_SUBSECTION_BLOCK;
}
function FileSubsection({ subsection }: FileSubsectionProps) {
  const [showForms, toggleForms] = useState<boolean>(false);
  const handleSpanClick = () => toggleForms(!showForms);
  const NewAnimatePresence: React.FC<NewAnimatePresenceProps> = AnimatePresence;
  const { name: sectionName } = subsection;
  const spanContent = showForms ? "-" : "+";
  const subsectionForms = HR_FILES.filter(
    (hrFile: HR_FILE) => hrFile.subsection === sectionName
  ).map((hrFile: HR_FILE, index) => <File file={hrFile} i={index} />);
  const downloadableForms = <motion.ul>{subsectionForms}</motion.ul>;
  return (
    <motion.section
      className="subsection__container"
      animate="animate"
      initial="initial"
      exit={{
        y: -50,
        opacity: 0,
        transition: {
          y: { stiffness: 2500 },
        },
      }}
      variants={ulVariants}
    >
      <article className="hr-subsection-file__container">
        <motion.span
          whileHover={{ scale: 1.02, color: "#c09243" }}
          whileTap={{ scale: 1.04, color: "#c09243" }}
          onClick={handleSpanClick}
        >
          {spanContent}
        </motion.span>
        <article className="hr-file-subsection__label">{sectionName}</article>
      </article>
      <NewAnimatePresence>{showForms && downloadableForms}</NewAnimatePresence>
    </motion.section>
  );
}

export default FileSubsection;
