const checkAuth = (authStatus: string): boolean => {
  let isAuthenticated = false;
  if (authStatus !== null) {
    if (authStatus === "false") {
      isAuthenticated = false;
    } else {
      isAuthenticated = true;
    }
  }
  return isAuthenticated;
};

export default checkAuth;
