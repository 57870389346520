import React, { useState } from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormControl from "@mui/material/FormControl";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Fab from "@mui/material/Fab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { MenuProps as MenuPropsType } from "@mui/material/Menu";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Viewer } from "@react-pdf-viewer/core";

import TextEditor from "../TextEditor";

import { FormValue, PostFormat, PostFormData } from "../../@types";
import { DEPARTMENTS, POST_FORMATS } from "../../common/constants";

import "./styles.scss";

interface FormProps {
  editorRef: React.MutableRefObject<any>;
  formData: PostFormData;
  isSuperUser: boolean;
  updateState: (content: FormValue, key: string) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps: Partial<MenuPropsType> = {
  variant: "menu",
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Form({ editorRef, formData, isSuperUser, updateState }: FormProps) {
  const val = [] as unknown as string;
  const {
    body_img: bodyImg,
    title,
    subtitle,
    format,
    archived_date: archiveDate,
    header_img: headerImg,
    publish_date: publishDate,
    existingBodyImage,
    video_link: videoLink,
    body,
    pdf_img: pdfImg,
    pdf_link: pdfLink,
    existingImage,
  } = formData;
  const [newTags, setNewTags] = useState<string>(val);
  const showExistingImg = existingImage;
  const formatOptions = POST_FORMATS.map((f) => {
    const buttonColor = f === format ? "primary" : "standard";
    return (
      <ToggleButton color={buttonColor} key={f} value={f}>
        {f}
      </ToggleButton>
    );
  });
  const removeHeaderImg = () => updateState(null, "headerImg");
  const removeBodyImg = () => updateState(null, "bodyImg");
  const removePdfImg = () => updateState(null, "pdfImg");

  const handleTitleInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateState(e.target.value, "title");
  const handleSubtitleInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateState(e.target.value, "subtitle");
  const handleDateChange = (newValue: Date | null | number) =>
    updateState(newValue, "publishDate");
  const handleArchiveDateChange = (newValue: Date | null | number) =>
    updateState(newValue, "archiveDate");
  const handleVideoLinkChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateState(e.target.value, "videoLink");
  const handleFormatBtnClick = (
    _evt: React.MouseEvent<HTMLElement>,
    formatToSet: PostFormat
  ) => updateState(formatToSet, "format");

  const handleTagsInput = (e: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = e;
    setNewTags(value);
    updateState(value, "tags");
  };
  const handleImageUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    state: string
  ) => {
    if (!e.target.files) {
      return;
    } else {
      const files: FileList = e.target.files as FileList;
      const imgFile = files[0];
      const reader = new FileReader();
      reader.onload = (evt: ProgressEvent<FileReader>) => {
        if (!e.target.files) {
          return null;
        }
        updateState(
          {
            file: e.target.files[0],
            id: 0,
            src: evt.target?.result as string,
          },
          state
        );
      };
      reader.readAsDataURL(imgFile);
    }
  };
  const handleImageOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleImageUpload(e, "headerImg");
  const handleBodyImageOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleImageUpload(e, "bodyImg");
  const handlePdfImageOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleImageUpload(e, "pdfImg");
  const imgInput = headerImg ? (
    <article className="image-upload__container">
      <header>Header Image:</header>
      <img src={headerImg.src} alt={headerImg.src} />{" "}
      <span className="remove-img__btn" onClick={removeHeaderImg}>
        X
      </span>
    </article>
  ) : (
    <Button
      sx={{ marginY: 3, overflow: "hidden" }}
      variant="contained"
      startIcon={<AddPhotoAlternateIcon />}
    >
      <input
        type="file"
        onChange={handleImageOnChange}
        accept="image/*"
        style={{
          //make this hidden and display only the icon
          position: "absolute",
          top: "-35px",
          left: 0,
          height: "calc(100% + 36px)",
          width: "calc(100% + 5px)",
          outline: "none",
        }}
      />
      Upload Header Image
    </Button>
  );
  const pdfInput = () => {
    if (pdfLink && !pdfImg) {
      return (
        <>
          <article className="image-upload__container">
            <header>Existing PDF Image:</header>
            <Viewer fileUrl={pdfLink} />
          </article>
          <article className="body-image__container">
            <header>Upload new PDF image:</header>
            <Fab color="primary" aria-label="Add Header Image">
              <input
                type="file"
                onChange={handlePdfImageOnChange}
                accept="application/pdf"
                style={{
                  //make this hidden and display only the icon
                  position: "absolute",
                  top: "-35px",
                  left: 0,
                  height: "calc(100% + 36px)",
                  width: "calc(100% + 5px)",
                  outline: "none",
                }}
              />
              <AddPhotoAlternateIcon />
            </Fab>
          </article>
        </>
      );
    }
    if (pdfImg) {
      return (
        <article className="image-upload__container">
          <header>PDF Image:</header>
          <Viewer fileUrl={pdfImg.src} />
          <span className="remove-img__btn" onClick={removePdfImg}>
            X
          </span>
        </article>
      );
    }
    return (
      <article className="body-image__container">
        <header>Upload PDF Body Image:</header>
        <Fab color="primary" aria-label="Add Header Image">
          <input
            type="file"
            onChange={handlePdfImageOnChange}
            accept="application/pdf"
            style={{
              //make this hidden and display only the icon
              position: "absolute",
              top: "-35px",
              left: 0,
              height: "calc(100% + 36px)",
              width: "calc(100% + 5px)",
              outline: "none",
            }}
          />
          <AddPhotoAlternateIcon />
        </Fab>
      </article>
    );
  };
  const bodyImgInput = () => {
    if (existingBodyImage && !bodyImg) {
      return (
        <>
          <article className="image-upload__container">
            <header>Existing Body Image:</header>
            <img src={existingBodyImage} alt={subtitle} />{" "}
          </article>
          <article className="body-image__container">
            <header>Upload new Body image:</header>
            <Fab color="primary" aria-label="Add Header Image">
              <input
                type="file"
                onChange={handleBodyImageOnChange}
                accept="image/*"
                style={{
                  //make this hidden and display only the icon
                  position: "absolute",
                  top: "-35px",
                  left: 0,
                  height: "calc(100% + 36px)",
                  width: "calc(100% + 5px)",
                  outline: "none",
                }}
              />
              <AddPhotoAlternateIcon />
            </Fab>
          </article>
        </>
      );
    }
    if (bodyImg) {
      return (
        <article className="image-upload__container">
          <header>Body Image:</header>
          <img src={bodyImg.src} alt={bodyImg.src} />{" "}
          <span className="remove-img__btn" onClick={removeBodyImg}>
            X
          </span>
        </article>
      );
    }
    return (
      <article className="body-image__container">
        <header>Upload new Body image:</header>
        <Fab color="primary" aria-label="Add Header Image">
          <input
            type="file"
            onChange={handleBodyImageOnChange}
            accept="image/*"
            style={{
              //make this hidden and display only the icon
              position: "absolute",
              top: "-35px",
              left: 0,
              height: "calc(100% + 36px)",
              width: "calc(100% + 5px)",
              outline: "none",
            }}
          />
          <AddPhotoAlternateIcon />
        </Fab>
      </article>
    );
  };
  const existingImageRender = showExistingImg && (
    <figure className="existing-image__wrapper">
      <img alt="Existing post thumbnail" src={existingImage} />
      <figcaption>Existing Image</figcaption>
    </figure>
  );
  const wysiwygEditor = (
    <TextEditor editorRef={editorRef} savedStoryBody={body} />
  );
  const videoLinkInput = (
    <>
      <TextEditor editorRef={editorRef} savedStoryBody={body} />
      <TextField
        value={videoLink}
        onChange={handleVideoLinkChange}
        label="Link To Video"
      />
    </>
  );
  const bodyInput = () => {
    if (format === "text") {
      return wysiwygEditor;
    }
    if (format === "video") {
      return videoLinkInput;
    }
    if (format === "pdf") {
      return pdfInput();
    }
    return bodyImgInput();
  };
  return (
    <form id="post-form__container">
      <TextField label="Title" value={title} onChange={handleTitleInput} />
      <TextField
        label="Subtitle"
        value={subtitle}
        onChange={handleSubtitleInput}
      />
      {isSuperUser && (
        <FormControl>
          <Select
            multiple
            value={newTags}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            onChange={handleTagsInput}
          >
            {DEPARTMENTS.map((dept) => (
              <MenuItem key={dept} value={dept}>
                {dept}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Publish Date"
          inputFormat="MM/dd/yyyy"
          value={publishDate}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Archive Date"
          inputFormat="MM/dd/yyyy"
          value={archiveDate}
          onChange={handleArchiveDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      {existingImageRender}
      {imgInput}
      <ToggleButtonGroup
        exclusive
        onChange={handleFormatBtnClick}
        value={format}
        aria-label="post-format"
      >
        {formatOptions}
      </ToggleButtonGroup>
      {bodyInput()}
    </form>
  );
}

export default Form;
