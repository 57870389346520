import { SUPER_USER_KEY } from "../common/constants";

const checkIfSuperUser = (): boolean => {
  const isSuperUserRawVal = localStorage.getItem(SUPER_USER_KEY);
  let isSuperUser = false;
  if (isSuperUserRawVal) {
    return JSON.parse(isSuperUserRawVal) as boolean;
  }
  return isSuperUser;
};

export default checkIfSuperUser;
