import { combineReducers } from "redux";

import events from "./events";
import posts from "./posts";
import weather from "./weather";
import youtube from "./youtube";
import faqs from "./faqs";
import profiles from "./profiles";

const rootReducer = combineReducers({
  events,
  faqs,
  posts,
  weather,
  youtube,
  profiles,
});

export default rootReducer;
