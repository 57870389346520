import { useState } from "react";
import { RRule, rrulestr } from "rrule";

import Button from "@mui/material/Button";

import Form from "./Form";

import {
  Department,
  EventFormData,
  FormValue,
  RCTVFormProps,
} from "../../@types";

interface EventFormProps extends RCTVFormProps {
  eventData: EventFormData;
}

function EventForm({ handleConfirm, isSuperUser, eventData }: EventFormProps) {
  const {
    title: initialTitle,
    event_description: initialDescription,
    end_date: initialEndDate,
    start_date: initialStartDate,
    tags: initialTags,
    rrulestr: rrulestrToParse,
  } = eventData;
  let parsedRrule = undefined;
  if (rrulestrToParse) {
    try {
      parsedRrule = rrulestr(rrulestrToParse);
    } catch (err) {
      parsedRrule = undefined;
    }
  }
  const [title, setTitle] = useState<string>(initialTitle);
  const [description, setEventDescription] =
    useState<string>(initialDescription);
  const [end_date, setEndDate] = useState<string | Date>(initialEndDate);
  const [start_time, setStartTime] = useState<string>("08:00");
  const [end_time, setEndTime] = useState<string>("17:00");
  const [start_date, setStartDate] = useState<string | Date>(initialStartDate);
  const [tags, setTags] = useState<Array<Department>>(initialTags);
  const [eventRrule, setRrule] = useState<RRule | undefined>(parsedRrule);

  const updateState = (value: FormValue, key: string) => {
    const valToSet: FormValue = value as string;
    if (key === "rrule") {
      return setRrule(valToSet as unknown as RRule);
    }
    if (key === "title") {
      return setTitle(valToSet);
    }
    if (key === "description") {
      return setEventDescription(valToSet);
    }
    if (key === "end_date") {
      return setEndDate(valToSet);
    }
    if (key === "start_date") {
      return setStartDate(valToSet);
    }
    if (key === "end_time") {
      return setEndTime(valToSet);
    }
    if (key === "start_time") {
      return setStartTime(valToSet);
    }
    if (key === "tags") {
      return setTags(valToSet as unknown as Array<Department>);
    }
  };
  const formData: EventFormData = {
    title,
    event_description: description,
    end_date,
    start_date,
    tags,
    start_time,
    end_time,
    rrule: eventRrule,
    rrulestr: rrulestrToParse,
  };
  const handleSaveClick = () => handleConfirm(formData);
  return (
    <div>
      <main>
        <Form
          formData={formData}
          updateState={updateState}
          isSuperUser={isSuperUser}
        />
        <Button onClick={handleSaveClick} color="success" variant="contained">
          Save Event
        </Button>
      </main>
    </div>
  );
}

export default EventForm;
