import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apiUrl from "../constants/apiUrl";
import { YoutubeVideo } from "../@types";

const baseApiUrl = `${apiUrl}`;

export const GET_YOUTUBE_CATEGORIES = "GET_YOUTUBE_CATEGORIES";
export const getYoutubeCategories = createAsyncThunk(
  GET_YOUTUBE_CATEGORIES,
  async () => {
    const categoryUrl = `${baseApiUrl}/playlists`;
    const res: AxiosResponse = await axios.get(categoryUrl);
    return res.data;
  }
);

export const GET_PLAYLIST_VIDEOS = "GET_PLAYLIST_VIDEOS";
export const getPlaylistVideos = createAsyncThunk(
  GET_PLAYLIST_VIDEOS,
  async (playlistId: string) => {
    const playlistUrl = `${baseApiUrl}/ytvids/${playlistId}`;
    const res: AxiosResponse = await axios.get(playlistUrl);
    return res.data;
  }
);

export const SET_MAIN_VIDEO = "SET_MAIN_VIDEO";
export const setMainVideo = createAsyncThunk(
  SET_MAIN_VIDEO,
  async (vid: YoutubeVideo) => {
    return vid;
  }
);
