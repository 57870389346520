export const storyContainerVariant = {
  animate: {
    transition: { staggerChildren: 0.3, delayChildren: 0.45 },
  },
};
export const postTagsContainerVariant = {
  animate: {
    transition: { staggerChildren: 0.3, delayChildren: 0.9 },
  },
};
export const postTitleVariant = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

export const postSubtitleVariant = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

export const postImageVariant = {
  initial: { x: -20, opacity: 0 },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};
export const postTagsVariant = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.3,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};
export const postBodyVariant = {
  initial: { y: -50, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.3,
      delay: 1.5,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};
