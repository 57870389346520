import { POST, RCTV_EVENT } from "../@types";
import getUserDepartment from "./getUserDepartment";

function checkIfUserHasPrivileges(data: POST | RCTV_EVENT): boolean {
  const userDepartment = getUserDepartment();
  if (userDepartment) {
    return data.tags.includes(userDepartment);
  }
  return false;
}

export default checkIfUserHasPrivileges;
