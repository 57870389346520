import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AnimatePresence, motion } from "framer-motion";

import Skeleton from "@mui/material/Skeleton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { NewAnimatePresenceProps, POST } from "../../../@types";

import { DEPARTMENT_COLORS } from "../../../common/constants";

import PostItem from "./PostItem";

interface TownhallCarouselProps {
  posts: Array<POST>;
}

function TownhallCarousel({ posts }: TownhallCarouselProps) {
  const [selectedIndex, setIndex] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const navigate = useNavigate();
  const NewAnimatePresence: React.FC<NewAnimatePresenceProps> = AnimatePresence;
  useEffect(() => {
    if (progress === 7) {
      const newIndex =
        selectedIndex === posts.length - 1 ? 0 : selectedIndex + 1;
      setIndex(newIndex);
      setProgress(0);
      return;
    }

    const intervalId = setInterval(() => {
      setProgress(progress + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [posts.length, progress, selectedIndex]);
  const atStartOfCarousel = selectedIndex === 0;
  const atEndOfCarousel = selectedIndex === posts.length - 1;
  const navigateToPost = () => navigate(`/post/${posts[selectedIndex].id}`);
  const handleGoBack = () => {
    setIndex(atStartOfCarousel ? 0 : selectedIndex - 1);
    if (!atStartOfCarousel) {
      setProgress(0);
    }
  };
  const handleGoForward = () => {
    setIndex(atEndOfCarousel ? 0 : selectedIndex + 1);
    if (!atEndOfCarousel) {
      setProgress(0);
    }
  };
  const goToPost = (index: number) => {
    setIndex(index);
    setProgress(0);
  };
  const selectedPost = posts[selectedIndex];
  if (selectedPost) {
    const department = selectedPost.tags[0];
    const title = selectedPost.title;

    const postsToShow = posts.map((p, i) => {
      const isSelected = selectedIndex === i;
      return (
        <PostItem
          key={p.id}
          index={i}
          handleClick={goToPost}
          post={p}
          isSelected={isSelected}
          progress={progress}
        />
      );
    });
    const departmentColors = DEPARTMENT_COLORS.find(
      (dc) => dc.departmentName === department
    );
    return (
      <motion.div className="carousel__container">
        <motion.span
          whileHover={{ y: 1 }}
          style={{
            color: atStartOfCarousel ? "darkgrey" : "white",
            position: "absolute",
            cursor: "pointer",
            left: 3,
            top: "50%",
            zIndex: 1000,
          }}
          onClick={handleGoBack}
        >
          <ArrowBackIosIcon />
        </motion.span>
        <motion.span
          whileHover={{ x: 1 }}
          onClick={handleGoForward}
          style={{
            color: atEndOfCarousel ? "darkgrey" : "white",
            cursor: "pointer",
            position: "absolute",
            right: 3,
            top: "50%",
            zIndex: 1000,
          }}
        >
          <ArrowForwardIosIcon />
        </motion.span>
        <motion.div className="vignette-2" />
        <NewAnimatePresence>
          <motion.section
            className="content__container"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <img src={selectedPost.header_img} alt="carousel background" />
            <h2>{title}</h2>
            <button onClick={navigateToPost} className="read-more__button">
              Read More
            </button>
            <span
              className="department-tag__text selected-carousel-department"
              style={{
                color: departmentColors?.textColor,
                backgroundColor: departmentColors?.bgColor,
              }}
            >
              {department}
            </span>
          </motion.section>
        </NewAnimatePresence>
        <section className="ceo-posts__container">{postsToShow}</section>
      </motion.div>
    );
  }
  return <Skeleton />;
}

export default TownhallCarousel;
