import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import RCTVSnackbar from "../../components/Snackbar";
import PostContent from "../../components/Posts/PostContent";

import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";

import { editPost, getPost } from "../../actions/posts";

import { POST, SNACKBAR_STATUSES } from "../../@types";
import { useAppDispatch } from "../../config/hooks";
import formatDate from "../../modules/formatDate";
import { ADMIN_USER_KEY } from "../../common/constants";

interface ReviewPostParams {
  id?: string | number;
}

function ReviewPost() {
  const navigate = useNavigate();
  const { id }: ReviewPostParams = useParams();
  const [postToReview, setPostToReview] = useState<POST>();
  const [snackbarOpen, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<SNACKBAR_STATUSES>(
    SNACKBAR_STATUSES.SUCCESS
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    async function getPostFromParams(id: string | number) {
      const { payload } = await dispatch(getPost(id));
      const retrievedPost: POST = payload.data[0] as POST;
      setPostToReview(retrievedPost);
    }
    if (id) {
      getPostFromParams(id);
    }
  }, [dispatch, id]);
  if (postToReview) {
    const showSnackbarMessage = (msg: string, severity: SNACKBAR_STATUSES) => {
      setSnackbarMessage(msg);
      setSnackbarSeverity(severity);
      return setSnackbar(true);
    };
    const handleReviewConfirm = async () => {
      let authUser = localStorage.getItem(ADMIN_USER_KEY);
      if (!authUser) {
        authUser = "default_admin";
      }
      const updatedPost: POST = {
        ...postToReview,
        approval_date: formatDate(new Date()),
        approved_by: authUser,
      };
      const res = await dispatch(editPost(updatedPost));
      if (res.meta.requestStatus === "fulfilled") {
        return navigate("/admin");
      }
      return showSnackbarMessage(
        "Failed to save changes, please contact Jonathan",
        SNACKBAR_STATUSES.ERROR
      );
    };
    return (
      <>
        <PostContent post={postToReview} />
        <footer>
          <Button
            variant="contained"
            color="success"
            onClick={handleReviewConfirm}
          >
            Confirm Review
          </Button>
        </footer>
        <RCTVSnackbar
          isOpen={snackbarOpen}
          severity={snackbarSeverity}
          setSnackbar={setSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </>
    );
  }
  return (
    <div className="story__container">
      <Skeleton animation="wave" />
    </div>
  );
}

export default ReviewPost;
