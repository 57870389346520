import { motion, Variants } from "framer-motion";

import { YoutubeCategory } from "../../@types";

interface YoutubeCategoryProps {
  cat: YoutubeCategory;
  handleClick: Function;
  index: number;
}

function YTCategory({ cat, handleClick, index }: YoutubeCategoryProps) {
  const handleCatClick = () => handleClick(cat.playlist_id, cat.playlist_name);
  const variants: Variants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { delay: index * 0.1, duration: 0.6 },
    },
    hidden: { opacity: 0, x: -100 },
  };
  return (
    <motion.article
      variants={variants}
      custom={index}
      className="youtube-category__playlist-wrapper"
    >
      <header className="youtube-category__playlist-header">Category</header>
      <sub onClick={handleCatClick} className="youtube-category__playlist-name">
        {cat.playlist_name}
      </sub>
    </motion.article>
  );
}

export default YTCategory;
