import { useEffect, useState } from "react";

import Profile from "./Profile";

import { getProfiles } from "../../actions/profiles";
import { Department, PROFILE } from "../../@types";

import { useAppDispatch, useAppSelector } from "../../config/hooks";

interface ProfileProps {
  department: Department;
}

function Profiles({ department }: ProfileProps) {
  const dispatch = useAppDispatch();
  const profileStatus = useAppSelector((state) => state.profiles.status);
  const profiles = useAppSelector((state) => state.profiles.profiles);
  const [savedProfiles, setSavedProfiles] = useState<Array<PROFILE>>([]);
  useEffect(() => {
    if (profileStatus === "idle") {
      dispatch(getProfiles());
    }
    if (profileStatus === "succeeded") {
      setSavedProfiles(profiles);
    }
  }, [profiles, profileStatus, dispatch]);

  const departmentProfiles = savedProfiles
    .filter((p: PROFILE) =>
      p && p.department
        ? p.department.toLocaleLowerCase() === department.toLocaleLowerCase()
        : false
    )
    .map((p: PROFILE) => <Profile data={p} key={p.id} />);

  return (
    <div>
      <ul>{departmentProfiles}</ul>
    </div>
  );
}

export default Profiles;
