import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";

import {
  PROFILE,
  RouteParams,
  SNACKBAR_STATUSES,
  UPDATE_PROFILE_REQUEST,
} from "../../@types";
import { editProfile, getProfile } from "../../actions/profiles";
import { useAppDispatch } from "../../config/hooks";

import GoBackButton from "../../components/GoBackButton";
import Profile from "../../components/Profiles/Profile";

import { ADMIN_HOME_LINK, ADMIN_USER_KEY } from "../../common/constants";
import formatDateForDjango from "../../modules/formatDateForDjango";
import RCTVSnackbar from "../../components/Snackbar";

function ReviewProfile() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id }: RouteParams = useParams();
  const [profileData, setProfileData] = useState<PROFILE>();
  const [snackbarOpen, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<SNACKBAR_STATUSES>(
    SNACKBAR_STATUSES.SUCCESS
  );

  useEffect(() => {
    async function getProfileFromParams(id: string | number) {
      const { payload } = await dispatch(getProfile(id));
      const retrievedProfile: PROFILE = payload.data[0] as PROFILE;
      setProfileData(retrievedProfile);
    }
    if (id) {
      getProfileFromParams(id);
    }
  }, [dispatch, id]);

  const showSnackbarMessage = (msg: string, severity: SNACKBAR_STATUSES) => {
    setSnackbarMessage(msg);
    setSnackbarSeverity(severity);
    return setSnackbar(true);
  };
  const handleReviewConfirm = async () => {
    let authUser = localStorage.getItem(ADMIN_USER_KEY);
    if (!authUser) {
      authUser = "default_admin";
    }
    if (!profileData) {
      return showSnackbarMessage(
        "Unexpected error, please contact Jonathan",
        SNACKBAR_STATUSES.ERROR
      );
    }

    const updatedProfile: UPDATE_PROFILE_REQUEST = {
      ...profileData,
      approval_date: formatDateForDjango(new Date()),
      approved_by: authUser,
    };

    const res = await dispatch(editProfile(updatedProfile));

    if (res.meta.requestStatus === "fulfilled") {
      return navigate("/admin");
    }
    return showSnackbarMessage(
      "Failed to save changes, please contact Jonathan",
      SNACKBAR_STATUSES.ERROR
    );
  };

  const content = profileData ? (
    <Profile data={profileData} />
  ) : (
    <Skeleton width="100%" />
  );

  return (
    <div>
      <GoBackButton link={ADMIN_HOME_LINK} />
      <main>
        <header>Review Profile</header>
        {content}
        <Button
          variant="contained"
          color="success"
          onClick={handleReviewConfirm}
        >
          Confirm Review
        </Button>
      </main>
      <RCTVSnackbar
        isOpen={snackbarOpen}
        severity={snackbarSeverity}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </div>
  );
}

export default ReviewProfile;
