import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import LoginPage from "./LoginPage";
import EventTab from "./EventTab";
import Documentation from "../Documentation";
import AdminPosts from "../../components/AdminPosts";
import AdminFaqs from "../../components/AdminFaqs";
import AdminProfiles from "../../components/AdminProfiles";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import RCTVSnackbar from "../../components/Snackbar";

import { useAppDispatch } from "../../config/hooks";
import { signOut } from "../../actions/auth";
import { clearEvents, deleteEvent } from "../../actions/events";
import { clearPosts, deletePost } from "../../actions/posts";
import { clearFaqs, deleteFaq } from "../../actions/faqs";
import { clearProfiles, deleteProfile } from "../../actions/profiles";

import logo from "../../assets/images/rctv-logo.png";

import { ADMIN_KEY, ADMIN_TABS, ADMIN_TAB_KEY } from "../../common/constants";
import checkAuth from "../../modules/checkAuth";
import {
  ADMIN_TAB_OPTIONS,
  RCTV_EVENT,
  FAQ,
  POST,
  SNACKBAR_STATUSES,
  PROFILE,
  AdminItemType,
} from "../../@types";

import "./styles.scss";
import "../Departments/styles.scss";

function Admin() {
  const authStatus: string = localStorage.getItem(ADMIN_KEY) as string;
  const initialAdminKey = localStorage.getItem(ADMIN_TAB_KEY);
  let isAuthenticated: boolean = checkAuth(authStatus);
  const [authState, setAuthState] = useState<boolean>(isAuthenticated);
  const [selectedTab, setTab] = useState<ADMIN_TAB_OPTIONS>(
    initialAdminKey
      ? (initialAdminKey as ADMIN_TAB_OPTIONS)
      : ADMIN_TAB_OPTIONS.POST
  );
  const [isDeleteModalOpen, toggleDeleteModal] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<
    POST | RCTV_EVENT | FAQ | PROFILE | undefined
  >();
  const [itemTypeToDelete, setItemType] = useState<AdminItemType>("Event");
  const [editFaqMode, toggleFaqEditMode] = useState<boolean>(false);
  const [editPostMode, togglePostEditMode] = useState<boolean>(false);
  const [editProfileMode, toggleProfileEditMode] = useState<boolean>(false);
  const [snackbarOpen, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<SNACKBAR_STATUSES>(
    SNACKBAR_STATUSES.SUCCESS
  );
  const showSnackbarMessage = (msg: string, severity: SNACKBAR_STATUSES) => {
    setSnackbarMessage(msg);
    setSnackbarSeverity(severity);
    return setSnackbar(true);
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearPosts());
    dispatch(clearEvents());
    dispatch(clearFaqs());
    dispatch(clearProfiles());
  });
  const navigate = useNavigate();
  const handleGoHomeClick = () => navigate("/");
  const handlePostEditModeClick = () => togglePostEditMode(!editPostMode);
  const handleFaqEditMode = () => toggleFaqEditMode(!editFaqMode);
  const handleProfileEditMode = () => toggleProfileEditMode(!editProfileMode);
  const handleSignOutClick = () => {
    setAuthState(false);
    return dispatch(signOut());
  };
  const handleDeleteConfirm = async (eventType: AdminItemType) => {
    if (itemToDelete) {
      let deleteFunction = deleteFaq;
      if (eventType === "Event") {
        deleteFunction = deleteEvent;
      }
      if (eventType === "Post") {
        deleteFunction = deletePost;
      }
      if (eventType === "Profile") {
        deleteFunction = deleteProfile;
      }
      const res = await dispatch(deleteFunction(itemToDelete.id));
      if (res.meta.requestStatus === "fulfilled") {
        // show success message
        showSnackbarMessage(
          `${eventType} was deleted!`,
          SNACKBAR_STATUSES.SUCCESS
        );
        // close modal
        return toggleDeleteModal(false);
      }
      // show error
      return showSnackbarMessage(
        "There was an error, please contact Jonathan!",
        SNACKBAR_STATUSES.ERROR
      );
    }
  };
  const handleEventDeleteIconClick = (e: RCTV_EVENT) => {
    setItemType("Event");
    toggleDeleteModal(true);
    setItemToDelete(e);
  };
  const handleDeletePostModalOpen = (post: POST) => {
    setItemType("Post");
    toggleDeleteModal(true);
    return setItemToDelete(post);
  };

  const handleDeleteFaqModalOpen = (faq: FAQ) => {
    setItemType("FAQ");
    toggleDeleteModal(true);
    return setItemToDelete(faq);
  };
  const handleDeleteProfileModalOpen = (profile: PROFILE) => {
    setItemType("Profile");
    toggleDeleteModal(true);
    return setItemToDelete(profile);
  };
  const handleDeleteModalClose = () => {
    setItemType("Event");
    toggleDeleteModal(false);
    setItemToDelete(undefined);
  };

  const signOutButton = () => (
    <article className="sign-out-btn" onClick={handleSignOutClick}>
      Sign Out
    </article>
  );
  const tabs = ADMIN_TABS.map((t) => {
    const className =
      t === selectedTab
        ? "admin__sidebar__item--active"
        : "admin__sidebar__item";
    const handleTabClick = () => {
      localStorage.setItem(ADMIN_TAB_KEY, t);
      setTab(t);
    };
    return (
      <li onClick={handleTabClick} key={t} className={className}>
        {t}
      </li>
    );
  });
  const actionContent = (tab: ADMIN_TAB_OPTIONS) => {
    if (tab === ADMIN_TAB_OPTIONS.POST) {
      return (
        <>
          <article>
            <Link to="/admin/create">Create Post</Link>
          </article>
          <article onClick={handlePostEditModeClick}>
            {editPostMode ? "Done Editing" : "Manage Posts"}
          </article>
        </>
      );
    }
    if (tab === ADMIN_TAB_OPTIONS.FAQ) {
      return (
        <>
          <article>
            <Link to="/admin/faqs/create">Create FAQ</Link>
          </article>
          <article onClick={handleFaqEditMode}>
            {editFaqMode ? "Done Editing" : "Manage FAQ"}
          </article>
        </>
      );
    }

    if (tab === ADMIN_TAB_OPTIONS.PROFILES) {
      return (
        <>
          <article>
            <Link to="/profile/create">Create Profile</Link>
          </article>
          <article onClick={handleProfileEditMode}>
            {editProfileMode ? "Done Editing" : "Manage Profiles"}
          </article>
        </>
      );
    }
  };

  const tabContent = (tab: ADMIN_TAB_OPTIONS) => {
    if (tab === ADMIN_TAB_OPTIONS.POST) {
      return (
        <AdminPosts
          openDeleteModal={handleDeletePostModalOpen}
          editMode={editPostMode}
        />
      );
    }
    if (tab === ADMIN_TAB_OPTIONS.EVENTS) {
      return <EventTab handleDeleteClick={handleEventDeleteIconClick} />;
    }
    if (tab === ADMIN_TAB_OPTIONS.FAQ) {
      return (
        <AdminFaqs
          openDeleteModal={handleDeleteFaqModalOpen}
          editMode={editFaqMode}
        />
      );
    }
    if (tab === ADMIN_TAB_OPTIONS.PROFILES) {
      return (
        <AdminProfiles
          openDeleteModal={handleDeleteProfileModalOpen}
          editMode={editProfileMode}
        />
      );
    }
    if (tab === ADMIN_TAB_OPTIONS.DOCUMENTATION) {
      return <Documentation />;
    }
  };
  const content = () => {
    if (!authState) {
      return <LoginPage setAuthState={setAuthState} />;
    } else {
      return (
        <div className="admin__page">
          <aside className="admin__sidebar">
            <ul className="admin__sidebar__list">{tabs}</ul>
          </aside>
          <div className="admin__container">
            <header className="admin__header">
              <article>
                <span className="go-back__wrapper" onClick={handleGoHomeClick}>
                  <ArrowBackIcon />
                </span>
              </article>
              <section className="posts-actions__wrapper">
                {actionContent(selectedTab)}
                {signOutButton()}
              </section>
            </header>
            <article className="branding__wrapper">
              <img src={logo} alt="Rare Collectibles logo" />
            </article>
            <main>{tabContent(selectedTab)}</main>
          </div>
          {itemToDelete && (
            <DeleteConfirmModal
              isOpen={isDeleteModalOpen}
              handleClose={handleDeleteModalClose}
              handleDeleteConfirm={handleDeleteConfirm}
              itemType={itemTypeToDelete}
            />
          )}
          <RCTVSnackbar
            isOpen={snackbarOpen}
            severity={snackbarSeverity}
            setSnackbar={setSnackbar}
            snackbarMessage={snackbarMessage}
          />
        </div>
      );
    }
  };
  return content();
}

export default Admin;
