import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactHTMLParser from "react-html-parser";
import { useParams } from "react-router";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { FAQ } from "../../@types";

import getFaqByIdDjango from "../../modules/getFaqByIdDjango";
import { INITIAL_FAQ } from "../../common/constants";

interface FaqPageParams {
  id?: string | number;
}

export default function FaqPage() {
  const [faqToUse, setFaq] = useState<FAQ>(INITIAL_FAQ);
  const [faqError, setError] = useState<boolean>(false);
  const { id }: FaqPageParams = useParams();
  const navigate = useNavigate();
  const handleGoHomeClick = () => {
    navigate(-1);
  };
  useEffect(() => {
    getFaqByIdDjango(setFaq, id as string).catch((_e) => {
      setError(true);
    });
  }, [id]);
  if (faqError) {
    return (
      <div>
        <header> 404 Error</header>
        <section>
          <h4> Faq does not exist!</h4>
          <Link to="/">Go Home</Link>
        </section>
      </div>
    );
  }
  const { question, answer } = faqToUse;
  return (
    <div>
      <nav>
        <span className="go-back__wrapper" onClick={handleGoHomeClick}>
          <ArrowBackIcon />
        </span>
      </nav>
      <section>
        <header>{question}</header>
      </section>
      <section>{ReactHTMLParser(answer)}</section>
    </div>
  );
}
