import CreatePost from "../../../src/assets/images/Create-Post-Button.png";
import PostForm from "../../../src/assets/images/Intranet-documentation-2024.png";
import PreviewPost from "../../../src/assets/images/intranet-documentation-2024-2.png";
import SavePost from "../../../src/assets/images/intranet-documentation-2024-3.png";
import SavePostMessage from "../../../src/assets/images/Create-Post-Message.png";
import CreateFaq from "../../../src/assets/images/Create-FAQ.png";
import FaqForm from "../../../src/assets/images/FAQ-Form.png";
import SaveFaqMessage from "../../../src/assets/images/FAQ-Success-Message.png";
import CreateEvent from "../../../src/assets/images/Create-Event.png";
import EventForm from "../../../src/assets/images/Event-Form.png";
import EventMessage from "../../../src/assets/images/Event-Success-Message.png";
import CreateProfile from "../../../src/assets/images/Create-Profile.png";
import ProfileForm from "../../../src/assets/images/Profile-Form.png";
import ProfileMessage from "../../../src/assets/images/Profile-Success-Message.png";

import "./styles.scss";

function Documentation() {
  return (
    <div>
      <header className="buttons-header">
        <button className="individual-button">
          <a href="#POST">How to create a post</a>
        </button>
        <button className="individual-button">
          <a href="#FAQ">How to create a FAQ</a>
        </button>
        <button className="individual-button">
          <a href="#EVENT">How to create an event</a>
        </button>
        <button className="individual-button">
          <a href="#PROFILE">How to create a profile card</a>
        </button>
      </header>
      <div>
        <header>
          <h2 id="POST">How to create a post</h2>
        </header>
        <article>
          <ul>
            <li>
              When you log in, you will be prompted to the "Posts" tab where in
              the upper right corner of the screen you will see a "Create Post"
              button.
            </li>
            <br />
            <img src={CreatePost} className="resizing-img" />
            <li>
              You will be promted to the post form where you will fill in the
              following:
            </li>
            <ol>
              <li>Title</li>
              <li>Subtitle</li>
              <li>Publish Date</li>
              <li>Archive Date</li>
              <li>Header Image</li>
              <li>Post Body (Either: Text, Video, Image)</li>
            </ol>
            <br />
            <img src={PostForm} className="resizing-img" />
            <li>
              Once you have created a post and are ready to save it, you will
              see a "Preview Post" button on the left side of your screen.
            </li>
            <br />
            <img src={PreviewPost} className="resizing-img" />
            <li>
              You will see a preview of your how your post will look like. You
              can either click on "Save Post" or "Edit Post" to go back and make
              changes.
            </li>
            <br />
            <img src={SavePost} className="resizing-img" />
            <li>
              Once you save your post successfully you will see a sucess message
              in the bottom left of your screen.{" "}
              <img src={SavePostMessage} className="resizing-img-2" />
            </li>
            <li>
              Your post will need to be approved by Colleen before it can be
              seen by everyone else.
            </li>
          </ul>
        </article>
      </div>
      <div>
        <header>
          <h2 id="FAQ">How to create a FAQ</h2>
        </header>
        <article>
          <ul>
            <li>
              Navigate to "FAQ" tab on the right side of the page, you will then
              see a "Create Faq" button in the top right of the page.
            </li>
            <br />
            <img src={CreateFaq} className="resizing-img" />
            <li>
              {" "}
              You will then be prompted to the FAQ form where you can fill in
              your question & answer.{" "}
            </li>
            <img src={FaqForm} className="resizing-img" />
            <li>
              Once you are ready to submit your FAQ, click on "Save FAQ" and you
              will be given a success message in the bottom left of your screen.
              You can now see your FAQ in your corresponding dept page.
            </li>{" "}
            <img src={SaveFaqMessage} className="resizing-img-2" />
          </ul>
        </article>
      </div>
      <div>
        <header>
          <h2 id="EVENT">How to create an event</h2>
        </header>
        <article>
          <ul>
            <li>
              Navigate to the "Events" tab on the left side of the screen and
              you will see a green box with a cross in the middle, click that to
              create a new event.{" "}
            </li>
            <br />
            <img src={CreateEvent} className="resizing-img" />
            <br />
            <li>
              You will then be promted to an event form where you will fill in
              the following
            </li>
            <br />
            <ol>
              <li>Title</li>
              <li>Description</li>
              <li>Department</li>
              <li>Start Date</li>
              <li>Frequency of event</li>
              <li>End Date</li>
            </ol>
            <br />
            <img src={EventForm} className="resizing-img" />
            <li>
              Once you are ready to submit your event, click on "Save Event" and
              you will be given a success message in the bottom left of your
              screen. You can now see your Event in the calendar section of the
              Intranet page.{" "}
              <img src={EventMessage} className="resizing-img-2" />
            </li>
          </ul>
        </article>
      </div>
      <div>
        <header>
          <h2 id="PROFILE">How to create a profile card</h2>
        </header>
        <article>
          <ul>
            <li>
              Navigate to the "Profiles" tab on the right side of the page, you
              will then see a "Create Profile" button in the top right of the
              page.
            </li>
            <br />
            <img src={CreateProfile} className="resizing-img" />
            <br />
            <li>
              You will then be promted to the profile form where you will fill
              in the following:
            </li>
            <ol>
              <li>Employee Name</li>
              <li>Employee Position</li>
              <li>Employee Email</li>
              <li>Upload Image</li>
            </ol>
            <br />
            <img src={ProfileForm} className="resizing-img" />
            <li>
              Once you are ready to submit your event, click on "Save Profile"
              and you will be given a success message in the bottom left of your
              screen. You can now see the created profile card in the
              corresponding dept page.
              <img src={ProfileMessage} className="resizing-img-2" />
            </li>
          </ul>
        </article>
      </div>
    </div>
  );
}

export default Documentation;
