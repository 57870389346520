import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  getProfiles,
  createProfile,
  deleteProfile,
  clearProfiles,
  editProfile,
} from "../actions/profiles";
import { PROFILE } from "../@types";

interface PROFILES_INITIAL_STATE {
  profiles: Array<PROFILE>;
  status: "idle" | "pending" | "succeeded" | "failed";
  error: any;
}

const initialState: PROFILES_INITIAL_STATE = {
  profiles: [],
  status: "idle",
  error: null,
};

const profilesSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {},

  extraReducers(builder: ActionReducerMapBuilder<PROFILES_INITIAL_STATE>) {
    builder.addCase(getProfiles.rejected, (_state, action) => ({
      ...initialState,
      status: "failed",
      error: action,
    }));
    builder.addCase(getProfiles.fulfilled, (_state, action) => ({
      profiles: action.payload.data,
      error: null,
      status: "succeeded",
    }));
    builder.addCase(createProfile.fulfilled, (state, action) => {
      // TODO: GET CREATED PROFILE FROM
      const { payload } = action;
      // TODO: ADD CREATED PROFILE TO REDUX STATE
      const newArr = JSON.parse(JSON.stringify(state.profiles));
      newArr.push(payload);
      state.profiles = newArr;
    });

    builder.addCase(deleteProfile.fulfilled, (state, action) => {
      const profileId = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.profiles));
      const profileIdx = state.profiles.find((p) => p.id === profileId);
      newArr.splice(profileIdx, 1);
      state.profiles = newArr;
    });
    builder.addCase(clearProfiles.fulfilled, () => ({ ...initialState }));

    builder.addCase(editProfile.fulfilled, (state, action) => {
      const profileToEdit: PROFILE = action.payload as unknown as PROFILE;
      const newArr = JSON.parse(
        JSON.stringify(state.profiles)
      ) as unknown as Array<PROFILE>;
      const postIdx = state.profiles.findIndex(
        (p) => p.id === profileToEdit.id
      );
      newArr[postIdx] = profileToEdit;
      state.profiles = newArr;
    });
  },
});

export default profilesSlice.reducer;
