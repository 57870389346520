import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import FileSection from "./FileSection";
import HRFormModal from "../../../components/HRFormModal";
import Posts from "../../../components/Posts";
import Sidebar from "../../../components/Sidebar";
import Faqs from "../../../components/Faqs";

import { useAppDispatch } from "../../../config/hooks";
import { getWeather } from "../../../actions/weather";
import { clearPosts } from "../../../actions/posts";

import logo from "../../../assets/images/rctv-logo.png";

import { HR_SECTION } from "../../../@types";

const DEPARTMENT = "Human Resources";

const HR_SECTIONS: Array<HR_SECTION> = ["Handbooks", "Benefits", "Paylocity"];

function HumanResources() {
  const [isModalOpen, toggleModal] = useState<boolean>(false);
  const closeFormModal = () => toggleModal(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getWeather());
    dispatch(clearPosts());
  });

  return (
    <div className="App">
      <Sidebar />
      <main id="hr-page__container">
        <section style={{ marginBottom: 50 }}>
          <header>
            <img src={logo} alt="Rare Collectibles logo" />
          </header>
        </section>
        <section className="posts__container">
          <section className="posts__wrapper">
            <Posts department={DEPARTMENT} isHomePage={false} />
          </section>
          <aside>
            <header id="forms__header">Useful Forms</header>
            <article className="hr-files__container">
              {HR_SECTIONS.map((section) => (
                <FileSection key={section} sectionName={section} />
              ))}
            </article>
            <article>
              <header id="forms__header">HR Team</header>
              <ul>
                <li className="useful-links__list-item">
                  <Link to="/department/humanresources/hrteam">
                    Meet the team
                  </Link>
                </li>
              </ul>
            </article>
            <article>
              <header id="forms__header"> FAQ:</header>
              <Faqs department={DEPARTMENT} />
            </article>
          </aside>
        </section>
      </main>
      <HRFormModal isOpen={isModalOpen} handleClose={closeFormModal} />
    </div>
  );
}

export default HumanResources;
