import { useNavigate } from "react-router";

import IconButton from "@mui/material/IconButton";
import RateReviewIcon from "@mui/icons-material/RateReview";

import { PROFILE } from "../../@types";
import { DEFAULT_DATE } from "../../common/constants";
import getUserDepartment from "../../modules/getUserDepartment";

interface ProfileProps {
  data: PROFILE;
  editMode: boolean;
  isSuperUser: boolean;
  openDeleteModal: Function;
}

function AdminProfile({
  data,
  editMode,
  isSuperUser,
  openDeleteModal,
}: ProfileProps) {
  const REVIEW_PAGE_LINK = `/admin/profile/review/${data.id}`;
  const { approval_date, employee_name, image, position, email, department } =
    data;
  const userDepartment = getUserDepartment();
  const userHasPrivileges = department === userDepartment;
  const handleDeleteModal = () => openDeleteModal(data);
  const navigate = useNavigate();
  const goToEditPage = () => navigate(`/admin/profile/edit/${data.id}`);
  const goToReviewPage = () => navigate(REVIEW_PAGE_LINK);
  const isApproved =
    approval_date && approval_date !== DEFAULT_DATE && approval_date !== "";
  const deleteButton = editMode && (
    <article onClick={handleDeleteModal}>
      <span className="delete-post__btn">X</span>
    </article>
  );
  const editButton = editMode && (
    <article onClick={goToEditPage}>
      <span className="edit-post__btn">Edit</span>
    </article>
  );
  const canEdit = editMode && (isSuperUser || userHasPrivileges);
  const showReviewButton = !isApproved;
  const reviewButton = showReviewButton && (
    <IconButton sx={{ color: "#5cb85c" }} onClick={goToReviewPage}>
      Review Profile
      <RateReviewIcon sx={{ paddingLeft: 1 }} />
    </IconButton>
  );
  return (
    <section className="team__wrapper">
      {canEdit && (
        <article className="admin-post__actions">
          {editButton}
          {deleteButton}
        </article>
      )}
      <figure className="team-member">
        <img src={image} alt="template" />
        <article>
          <h3 className="team-member-detail team-member--name">
            {employee_name}
          </h3>
          <h6 className="department">{department}</h6>
          <h5 className="team-member-detail team-member--title">{position}</h5>
          <h5 className="team-member-detail team-member--email">{email}</h5>
        </article>
      </figure>
      {reviewButton}
    </section>
  );
}

export default AdminProfile;
