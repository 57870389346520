import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  clearEvents,
  createEvents,
  deleteEvent,
  editEvent,
  getEvents,
} from "../actions/events";
import { RCTV_EVENT } from "../@types";

interface events_INITIAL_STATE {
  events: Array<RCTV_EVENT>;
  calendarEvents: Array<RCTV_EVENT>;
  status: "idle" | "pending" | "succeeded" | "failed";
  error: any;
}

const initialState: events_INITIAL_STATE = {
  events: [],
  calendarEvents: [],
  status: "idle",
  error: null,
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},

  extraReducers(builder: ActionReducerMapBuilder<events_INITIAL_STATE>) {
    builder.addCase(getEvents.rejected, (_state, action) => ({
      ...initialState,
      status: "failed",
      error: action,
    }));
    builder.addCase(getEvents.fulfilled, (_state, action) => ({
      events: action.payload.data,
      calendarEvents: action.payload.data.map((e: RCTV_EVENT) => ({
        ...e,
        start_date: new Date(e.start_date),
        end_date: new Date(e.end_date),
      })),
      error: null,
      status: "succeeded",
    }));
    builder.addCase(createEvents.fulfilled, (state, action) => {
      const { post } = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.events));
      newArr.push(post);
      state.events = newArr;
    });
    builder.addCase(deleteEvent.fulfilled, (state, action) => {
      const postId = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.events));
      const postIdx = state.events.find((p) => p.id === postId);
      newArr.splice(postIdx, 1);
      state.events = newArr;
    });
    builder.addCase(clearEvents.fulfilled, (state) => ({ ...initialState }));
    builder.addCase(editEvent.fulfilled, (state, action) => {
      const postToEdit: RCTV_EVENT = action.payload as unknown as RCTV_EVENT;
      const newArr = JSON.parse(
        JSON.stringify(state.events)
      ) as unknown as Array<RCTV_EVENT>;
      const postIdx = state.events.findIndex((p) => p.id === postToEdit.id);
      newArr[postIdx] = postToEdit;
      state.events = newArr;
    });
  },
});

export default eventsSlice.reducer;
