import { useEffect, useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

import AdminPost from "./AdminPost";

import { getPosts } from "../../actions/posts";
import { POST } from "../../@types";
import { useAppDispatch, useAppSelector } from "../../config/hooks";

interface AdminPostsProps {
  editMode: boolean;
  openDeleteModal: Function;
}

function AdminPosts({ editMode, openDeleteModal }: AdminPostsProps) {
  const dispatch = useAppDispatch();
  const postsStatus = useAppSelector((state) => state.posts.status);
  const posts = useAppSelector((state) => state.posts.posts);
  const [savedPosts, setPosts] = useState<Array<POST>>([]);
  const [viewApprovedPosts, togglePostsVisibility] = useState<boolean>(false);
  const headerText = viewApprovedPosts ? "Approved Posts" : "Unapproved Posts";
  const checkboxText = viewApprovedPosts
    ? "Approved Posts"
    : "Unapproved Posts";
  const handleCheckboxClick = () => togglePostsVisibility(!viewApprovedPosts);
  useEffect(() => {
    if (postsStatus === "idle") {
      dispatch(getPosts());
    }
    if (postsStatus === "succeeded") {
      setPosts(posts);
    }
  }, [posts, postsStatus, dispatch]);

  const postsToRender =
    savedPosts &&
    savedPosts.length > 0 &&
    savedPosts
      .filter((p: POST) => {
        if (p) {
          const isApproved = p.approval_date !== "" && p.approved_by !== "";
          return isApproved === viewApprovedPosts;
        }
        return false;
      })
      .map((p: POST) => (
        <AdminPost
          openDeleteModal={openDeleteModal}
          editMode={editMode}
          data={p}
          viewApprovedAdminPosts={viewApprovedPosts}
          key={p.id}
        />
      ));

  return (
    <section className="admin-post__container">
      <header className="admin-post__header">
        {headerText}{" "}
        <aside>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={viewApprovedPosts}
                  onClick={handleCheckboxClick}
                />
              }
              label={checkboxText}
            />
          </FormGroup>
        </aside>
      </header>
      <ul className="admin-post__wrapper">{postsToRender}</ul>
    </section>
  );
}

export default AdminPosts;
