import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { FAQ } from "../../@types";

interface FaqProps {
  data: FAQ;
}

function Faq({ data }: FaqProps) {
  const { question, answer } = data;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className="faq-question__text">{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="faq-answer__text">{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default Faq;
