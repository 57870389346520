import { useEffect } from "react";

import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";

import "react-big-calendar/lib/css/react-big-calendar.css";

import { useAppDispatch } from "../../config/hooks";
import { clearEvents } from "../../actions/events";
import EventCalendar from "./EventCalendar";

function Events() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearEvents());
  }, [dispatch]);
  return (
    <div className="App">
      <Sidebar />
      <main>
        <Header />
        <EventCalendar />
      </main>
    </div>
  );
}

export default Events;
