import { YoutubeVideo, YoutubeVideoThumbnail } from "../../@types";

interface RelatedVideoThumbnailProps {
  videoDetails: YoutubeVideo;
  handleThumbnailClick: Function;
}
function RelatedVideoThumbnail({
  handleThumbnailClick,
  videoDetails,
}: RelatedVideoThumbnailProps) {
  const {
    video_title: videoTitle,
    date_published: datePublished,
    thumbnail,
  } = videoDetails;
  const dateString = new Date(datePublished).toLocaleString("en-us", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  const thumb: YoutubeVideoThumbnail = JSON.parse(
    JSON.parse(JSON.stringify(thumbnail)).replace(/'/g, '"')
  );
  let imgThumbnail = null;
  if (thumb) {
    if (!thumb.default) {
      imgThumbnail = null;
    } else {
      const { width, height, url } = thumb.default;
      imgThumbnail = (
        <img src={url} alt={videoTitle} height={height} width={width} />
      );
    }
  }
  const handleClick = () => handleThumbnailClick(videoDetails)
  return (
    <li onClick={handleClick} className="related-video">
      {imgThumbnail}
      <article className="related-video__details">
        <figcaption>{videoTitle}</figcaption>
        <summary>{dateString}</summary>
      </article>
    </li>
  );
}

export default RelatedVideoThumbnail;
