// eslint-disable-no-useless-escape

function getYoutubeVideoId(ytLink: string): string {
  const regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = ytLink.match(regExp);
  if (match && match[2].length ===  11) {
    return match[2];
  }
  return "Error";
}

export default getYoutubeVideoId;
