import { useState } from "react";
import Button from "@mui/material/Button";

import Form from "./Form";

import {
  FormValue,
  RCTVFormProps,
  ProfileFormData,
  ImageState,
} from "../../@types";

import "./styles.scss";

interface ProfileFormProps extends RCTVFormProps {
  formData: ProfileFormData;
  defaultDepartment: string | null;
}

function ProfileForm({
  defaultDepartment,
  formData,
  handleConfirm,
}: ProfileFormProps) {
  const {
    employee_name: initialName,
    position: initialPosition,
    email: initialEmail,
    department: formDepartment,
  } = formData;
  const initialDepartment = defaultDepartment
    ? defaultDepartment
    : formDepartment;
  const [employee_name, setName] = useState<string>(initialName);
  const [position, setPosition] = useState<string>(initialPosition);
  const [email, setEmail] = useState<string>(initialEmail);
  const [department, setDepartment] = useState<string>(initialDepartment);
  const [image, setImage] = useState<ImageState | undefined>();

  const updateState = (value: FormValue, key: string) => {
    let valToSet: string | ImageState = value as string;
    if (key === "name") {
      return setName(valToSet);
    }
    if (key === "position") {
      return setPosition(valToSet);
    }
    if (key === "email") {
      return setEmail(valToSet);
    }
    if (key === "department") {
      valToSet = value as string;
      return setDepartment(valToSet);
    }
    if (key === "image") {
      valToSet = value as ImageState;
      return setImage(valToSet);
    }
  };

  const profileData: ProfileFormData = {
    ...formData,
    employee_name,
    position,
    email,
    department,
    imageToSet: image,
    approved_by: "",
  };

  const content = (
    <Form
      defaultDepartment={defaultDepartment}
      updateState={updateState}
      formData={profileData}
    />
  );
  const handleSaveClick = () => handleConfirm(profileData);

  return (
    <div id="post-page__container">
      <main>
        {content}
        <article>
          <Button onClick={handleSaveClick}>Save Profile</Button>
        </article>
      </main>
    </div>
  );
}

export default ProfileForm;
