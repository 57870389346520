import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import Skeleton from "@mui/material/Skeleton";

import PostForm from "../../components/PostForm";
import RCTVSnackbar from "../../components/Snackbar";

import { editPost, getPost } from "../../actions/posts";

import { useAppDispatch } from "../../config/hooks";
import checkIfSuperUser from "../../modules/checkIfSuperUser";
import formatDateForDjango from "../../modules/formatDateForDjango";
import uploadFilesToAws from "../../modules/uploadFilesToAws";
import {
  PostFormData,
  SNACKBAR_STATUSES,
  POST,
  ConfirmDataTypes,
} from "../../@types";
import { INITIAL_POST_FORM } from "../../common/constants";

import "./styles.scss";

interface EditPostParams {
  id?: string | number;
}

function EditPost() {
  const isSuperUser = checkIfSuperUser();
  const { id }: EditPostParams = useParams();
  const navigate = useNavigate();
  const [postData, updatePostData] = useState<PostFormData>(INITIAL_POST_FORM);
  const [existingPostData, setExistingPostData] = useState<POST>();
  const [renderForm, toggleForm] = useState<boolean>(false);
  const [snackbarOpen, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<SNACKBAR_STATUSES>(
    SNACKBAR_STATUSES.SUCCESS
  );
  const editorRef: any = useRef();
  const dispatch = useAppDispatch();
  useEffect(() => {
    async function getPostFromParams(id: string | number) {
      const { payload } = await dispatch(getPost(id));
      const retrievedPost: POST = payload.data[0] as POST;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: postId, date_modified, ...retrievedPostData } = retrievedPost;
      const { format, header_img: headerImg } = retrievedPostData;
      const retrievedPostFormData: PostFormData = {
        ...retrievedPostData,
        existingImage: headerImg,
        video_link: format === "video" ? retrievedPostData.body : "",
        header_img: undefined,
        existingBodyImage: format === "image" ? retrievedPostData.body : "",
      };
      updatePostData(retrievedPostFormData);
      setExistingPostData(retrievedPost);
      toggleForm(true);
    }
    if (id) {
      getPostFromParams(id);
    }
  }, [dispatch, id]);
  const handleConfirm = async (data: ConfirmDataTypes) => {
    const newPostData = data as PostFormData;
    if (id && existingPostData) {
      const { date_created } = existingPostData;
      const {
        tags,
        existingImage: existingImg,
        header_img: headerImg,
      } = newPostData;
      const tagsToSave = tags ? tags : [];
      let imgLinkToUse: string = existingImg ? existingImg : "";

      delete newPostData.existingImage;
      delete newPostData.existingBodyImage;

      if (headerImg) {
        const rawImages = (await uploadFilesToAws([headerImg])) as any;
        // get image location
        const [headerImgLink] = await Promise.all(rawImages);
        imgLinkToUse = headerImgLink;
      }

      const dataToSend: POST = {
        ...newPostData,
        tags: tagsToSave,
        id: id as unknown as number,
        header_img: imgLinkToUse,
        date_created: formatDateForDjango(date_created),
        date_modified: formatDateForDjango(new Date()),
        archived_date: formatDateForDjango(newPostData.archived_date),
        ceo_post: false,
      };

      // submit to axios
      const res = await dispatch(editPost(dataToSend));
      // get response
      let msg = "";
      if (res.meta.requestStatus === "rejected") {
        // set error
        msg += "Unable to edit post! Please contact Jonathan for assistance";
        setSnackbarMessage(msg);
        setSnackbarSeverity(SNACKBAR_STATUSES.ERROR);
        return setSnackbar(true);
      }
      // show success
      msg += "Edited Post!";
      setSnackbarMessage(msg);
      setSnackbarSeverity(SNACKBAR_STATUSES.SUCCESS);
      setSnackbar(true);
      return navigate("/admin");
    }
  };
  const content = renderForm ? (
    <PostForm
      editorRef={editorRef}
      isSuperUser={isSuperUser}
      postData={postData}
      handleConfirm={handleConfirm}
    />
  ) : (
    <Skeleton width="100%" />
  );
  return (
    <div id="post-page__container">
      <nav>
        <Link to="/admin">
          <article className="go-back">
            <HomeIcon />
            <h6>Go back to admin screen</h6>
          </article>
        </Link>
      </nav>
      <main>
        <header>
          <h4>Edit Post</h4>
        </header>
        {content}
        <RCTVSnackbar
          isOpen={snackbarOpen}
          severity={snackbarSeverity}
          setSnackbar={setSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </main>
    </div>
  );
}

export default EditPost;
