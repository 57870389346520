import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import Posts from "../../components/Posts";
import Sidebar from "../../components/Sidebar";

import logo from "../../assets/images/rctv-logo.png";
import calendar from "../../assets/images/calendar.png";
import coinIcon from "../../assets/images/coin-icon2.png";
import hrIcon from "../../assets/images/hricon.png";

import {
  containerVariants,
  contentVariants,
  noIntroContentVariant,
  pageContent,
  textVariants,
} from "./animations";
import { useAppDispatch } from "../../config/hooks";

import { clearPosts } from "../../actions/posts";
import { getWeather } from "../../actions/weather";

import checkLastDay from "../../modules/checkLastDay";

import "./styles.scss";

function Home() {
  const dispatch = useAppDispatch();
  const [useAnimations, toggleAnimations] = useState<boolean>(true);
  useEffect(() => {
    dispatch(getWeather());
    dispatch(clearPosts());
    const animateLayout = checkLastDay();
    toggleAnimations(animateLayout);
  }, [dispatch]);
  const INTRO_TEXT = "Rare Collectibles Intranet Site";
  const animatedWords = INTRO_TEXT.split(" \u00A0").map((word) => {
    return (
      <span key={word} style={{ overflow: "hidden", display: "inline-block" }}>
        <motion.span
          className="animated__text--active"
          style={{ display: "inline-block" }}
          variants={textVariants}
        >
          {word}
        </motion.span>
      </span>
    );
  });

  const animatedIntro = (
    <>
      <motion.div
        className="intro__wrapper"
        variants={containerVariants}
        animate="visible"
        initial="hidden"
      >
        <h4 className="animated-text__wrapper">{animatedWords}</h4>
      </motion.div>
      <Sidebar />
      <motion.main
        variants={contentVariants}
        initial="initial"
        animate="animate"
      >
        <motion.section style={{ marginBottom: 50 }} variants={pageContent}>
          <header>
            <img src={logo} alt="Rare Collectibles logo" />
          </header>
        </motion.section>
        <motion.section className="posts__container" variants={pageContent}>
          <section className="posts__wrapper">
            <Posts department="All" isHomePage={true} />
          </section>
          <aside className="useful-links__container">
            <article>
              <header className="useful-links__header">Useful Links</header>
              <ul className="useful-links__list">
                <li className="useful-links__list-item">
                  <img src={coinIcon} alt="Coin Icon" />
                  Liberty
                </li>
                <li className="useful-links__list-item">
                  <img src={calendar} alt="Digital Agenda Icon" />
                  Digital Agenda
                </li>
                <li className="useful-links__list-item">
                  <img src={hrIcon} alt="Human Resource Icon" />
                  Paylocity
                </li>
              </ul>
            </article>
          </aside>
        </motion.section>
      </motion.main>
    </>
  );
  const normalIntro = (
    <>
      <Sidebar />
      <motion.main
        variants={noIntroContentVariant}
        initial="initial"
        animate="animate"
      >
        <motion.section variants={pageContent} style={{ marginBottom: 50 }}>
          <header>
            <img src={logo} alt="Rare Collectibles logo" />
          </header>
        </motion.section>
        <motion.section variants={pageContent} className="posts__container">
          <section className="posts__wrapper">
            <Posts department="All" isHomePage={true} />
          </section>
          <aside className="useful-links__container">
            <article>
              <header className="useful-links__header">Useful Links</header>
              <ul className="useful-links__list">
                <li className="useful-links__list-item">
                  <img src={coinIcon} alt="Coin Icon" />
                  <a
                    href="https://liberty.rctvpact.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Liberty
                  </a>
                </li>
                <li className="useful-links__list-item">
                  <img src={calendar} alt="Digital Agenda Icon" />
                  Digital Agenda
                </li>
                <li className="useful-links__list-item">
                  <img src={hrIcon} alt="Human Resource Icon" />
                  <a
                    href="https://access.paylocity.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Paylocity
                  </a>
                </li>
              </ul>
            </article>
          </aside>
        </motion.section>
      </motion.main>
    </>
  );
  return (
    <div className="App">{useAnimations ? animatedIntro : normalIntro}</div>
  );
}

export default Home;
