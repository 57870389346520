import { useEffect, useState } from "react";

import Post from "./Post";

import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getPosts } from "../../actions/posts";
import handlePostFilter from "../../modules/handlePostFilter";
import { Department, POST } from "../../@types";
import TownhallCarousel from "./TownhallCarousel";

interface PostsProps {
  department: Department;
  isHomePage: boolean;
}

function Posts({ department, isHomePage }: PostsProps) {
  const dispatch = useAppDispatch();
  const postsStatus = useAppSelector((state) => state.posts.status);
  const posts = useAppSelector((state) => state.posts.posts);
  const [savedPosts, setPosts] = useState<Array<POST>>([]);
  useEffect(() => {
    if (postsStatus === "idle") {
      dispatch(getPosts());
    }
    if (postsStatus === "succeeded") {
      setPosts(posts);
    }
  }, [posts, postsStatus, dispatch]);

  const ceoPosts = savedPosts
    .filter((p: POST) => handlePostFilter(p, true, department))
    .sort(function (a, b) {
      const c = new Date(a.date_created).getTime();
      const d = new Date(b.date_created).getTime();
      return c > d ? -1 : 1;
    });

  const departmentPosts = savedPosts
    .filter((p: POST) => handlePostFilter(p, false, department))
    .sort(function (a, b) {
      const c = new Date(a.date_created).getTime();
      const d = new Date(b.date_created).getTime();
      return d - c;
    })
    .map((p: POST) => <Post data={p} key={p.id} />);

  return (
    <div style={{ width: "100%" }}>
      {isHomePage && (
        <>
          <header className="posts__header">Townhall Announcements</header>
          <TownhallCarousel posts={ceoPosts} />
        </>
      )}
      <h2 style={{ marginLeft: "1.7em" }}>{department} Announcements</h2>
      <ul
        style={{
          display: "grid",
          gap: "30px",
          gridTemplateColumns: "repeat(3, 1fr)",
        }}
      >
        {departmentPosts}
      </ul>
    </div>
  );
}

export default Posts;
