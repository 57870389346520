import React, { useState } from "react";
import { MenuProps as MenuPropsType } from "@mui/material/Menu";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import checkIfSuperUser from "../../modules/checkIfSuperUser";
import { DEPARTMENTS } from "../../common/constants";
import { FAQFormData } from "../../@types";

import "./styles.scss";

interface FaqFormProps {
  formData: FAQFormData;
  updateState: Function;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps: Partial<MenuPropsType> = {
  variant: "menu",
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function FaqForm({ formData, updateState }: FaqFormProps) {
  const isSuperUser = checkIfSuperUser();
  const val = [] as unknown as string;
  const [newTags, setNewTags] = useState<string>(val);
  const { question, answer } = formData;
  const handleQuestionInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateState(e.target.value, "question");
  const handleAnswerInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateState(e.target.value, "answer");

  const handleTagsInput = (e: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = e;
    setNewTags(value);
    updateState(value, "tags");
  };

  return (
    <form id="faq-form__container">
      <TextField
        label="Question"
        value={question}
        onChange={handleQuestionInput}
      />
      <TextField
        id="outlined-multiline-static"
        label="Answer"
        multiline
        rows={4}
        value={answer}
        onChange={handleAnswerInput}
      />
      {isSuperUser && (
        <FormControl>
          <Select
            label="Departments"
            value={newTags}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            onChange={handleTagsInput}
          >
            {DEPARTMENTS.map((dept) => (
              <MenuItem key={dept} value={dept}>
                {dept}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </form>
  );
}

export default FaqForm;
