function checkLastDay() {
  let hours = 24 // to clear the localStorage after 1 hour
  // (if someone want to clear after 8hrs simply change hours=8)
  let now = new Date().getTime().toString()
  const LOCALSTORAGE_KEY = "setupTime"
  let setupTime = localStorage.getItem(LOCALSTORAGE_KEY)
  const nowNum = parseInt(now, 10)
  if (setupTime === null) {
    localStorage.setItem(LOCALSTORAGE_KEY, now)
    return true
  } else {
    const setupTimeNum = parseInt(JSON.parse(setupTime), 10)
    if (nowNum - setupTimeNum > hours * 60 * 60 * 1000) {
      localStorage.clear()
      localStorage.setItem(LOCALSTORAGE_KEY, now)
      return true
    }
  }
  return false
}

export default checkLastDay
