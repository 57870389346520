import ReactHTMLParser from "react-html-parser";
import { Viewer } from "@react-pdf-viewer/core";

import { PostFormData } from "../../@types";

import getYoutubeVideoId from "../../modules/getYoutubeId";

import "./styles.scss";

interface PreviewPostProps {
  post: PostFormData;
}

export default function PreviewPost({ post }: PreviewPostProps) {
  const {
    header_img: headerImg,
    existingImage,
    title,
    subtitle,
    tags,
    pdf_img: pdfImg,
    body_img: bodyImg,
    body,
    format,
    video_link: videoLink,
    publish_date: publishDate,
    pdf_link: pdfLink,
  } = post;
  let imgSrc = existingImage;
  let bodySrc = "";
  let pdfSrc = pdfLink;
  if (bodyImg) {
    bodySrc = bodyImg.src;
  }
  if (pdfImg) {
    pdfSrc = pdfImg.src;
  }
  if (headerImg) {
    imgSrc = headerImg.src;
  }
  const youtubeId = getYoutubeVideoId(videoLink);
  const videoContent = (
    <article className="post-video__wrapper">
      <iframe
        width="560"
        height="315"
        allowFullScreen
        src={`//www.youtube.com/embed/${youtubeId}?rel=0`}
        title={title}
      />
    </article>
  );
  const pdfContent = (
    <section className="body-img__container">
      <Viewer fileUrl={pdfSrc} />
    </section>
  );
  const bodyImgContent = bodySrc.length > 0 && (
    <section className="body-img__container">
      <img src={bodySrc} alt="body for post" />
    </section>
  );
  const storyContent = (
    <section className="story__body">{ReactHTMLParser(body)}</section>
  );
  const tagsString: string = tags ? tags.join(", ") : "";
  const formattedDate = new Date(publishDate).toDateString();
  const postContent = () => {
    if (format === "text") {
      return storyContent;
    }
    if (format === "video") {
      return videoContent;
    }
    if (format === "pdf") {
      return pdfContent;
    }
    return bodyImgContent;
  };
  return (
    <div className="story__container">
      <section className="story__title">
        <header>{title}</header>
        <h6>{subtitle}</h6>
        <figure className="story__image">
          <img src={imgSrc} alt={imgSrc} />
        </figure>
      </section>
      <article className="story-misc__container">
        <span>{tagsString}</span>
        <sub>{formattedDate}</sub>
      </article>
      {postContent()}
    </div>
  );
}
