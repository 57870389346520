import { FunctionComponent } from 'react'

const NotFound: FunctionComponent = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <h1>404 Not Found</h1>
    </div>
  )
}

export default NotFound
