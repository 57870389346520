import { useState } from "react";

import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

import Form from "./Form";
import PreviewPost from "../../components/Posts/PreviewPost";

import {
  FormValue,
  ImageState,
  PostFormat,
  PostFormData,
  RCTVFormProps,
} from "../../@types";

import "./styles.scss";

interface PostFormProps extends RCTVFormProps {
  editorRef: React.MutableRefObject<any>;
  postData: PostFormData;
}

function PostForm({
  editorRef,
  handleConfirm,
  isSuperUser,
  postData,
}: PostFormProps) {
  const {
    title: initialTitle,
    existingBodyImage,
    subtitle: initialSubtitle,
    video_link,
    body_img,
    format: initialFormat,
    publish_date,
    archived_date,
    body: initialBody,
    pdf_link,
    tags: initialTags,
    existingImage,
  } = postData;
  const [title, setTitle] = useState<string>(initialTitle);
  const [subtitle, setSubtitle] = useState<string>(initialSubtitle);
  const [body, setBody] = useState<string>(initialBody);
  const [videoLink, setVideoLink] = useState<string>(video_link);
  const [bodyImg, setBodyImage] = useState<ImageState | undefined>(body_img);
  const [headerImg, setHeaderImg] = useState<ImageState | undefined>();
  const [pdfImg, setPdfImg] = useState<ImageState | undefined>();
  const [format, setFormat] = useState<PostFormat>(initialFormat);
  const [publishDate, setPublishedDate] = useState<any>(new Date(publish_date));
  const [archiveDate, setArchiveDate] = useState<any>(new Date(archived_date));
  const [tags, setTags] = useState<Array<string>>(initialTags as Array<string>);
  const [previewMode, togglePreviewMode] = useState<boolean>(false);
  const toggleText = previewMode ? "Edit Post" : "Preview Post";
  const updateState = (value: FormValue, key: string) => {
    let valToSet: boolean | Array<string> | string | Date | ImageState =
      value as string;
    if (key === "pdfImg") {
      valToSet = value as ImageState;
      return setPdfImg(valToSet);
    }
    if (key === "title") {
      return setTitle(valToSet);
    }
    if (key === "subtitle") {
      return setSubtitle(valToSet);
    }
    if (key === "format") {
      if (valToSet !== "text" && format === "text") {
        if (editorRef.current) {
          setBody(editorRef.current.getContent());
        }
      }
      return setFormat(valToSet as PostFormat);
    }
    if (key === "videoLink") {
      return setVideoLink(valToSet);
    }
    if (key === "publishDate") {
      valToSet = value as Date;
      return setPublishedDate(valToSet);
    }
    if (key === "archiveDate") {
      valToSet = value as Date;
      return setArchiveDate(valToSet);
    }
    if (key === "headerImg") {
      valToSet = value as ImageState;
      return setHeaderImg(valToSet);
    }
    if (key === "bodyImg") {
      valToSet = value as ImageState;
      return setBodyImage(valToSet);
    }
    if (key === "tags") {
      valToSet = value as Array<string>;
      return setTags(valToSet);
    }
  };
  const isBodyValid = (): boolean => {
    if (format === "text") {
      return true;
    }
    if (format === "video") {
      return videoLink.length > 10;
    }
    if (format === "pdf") {
      return pdfImg || pdf_link !== "" ? true : false;
    }
    return bodyImg ? true : false;
  };
  const showPreviewButton = format !== null && isBodyValid();
  const formData: PostFormData = {
    title,
    subtitle,
    body,
    pdf_img: pdfImg,
    existingBodyImage,
    tags,
    format,
    publish_date: publishDate,
    header_img: headerImg,
    video_link: videoLink,
    body_img: bodyImg,
    existingImage: existingImage as string,
    archived_date: archiveDate,
    pdf_link,
  };
  const content = previewMode ? (
    <PreviewPost post={formData} />
  ) : (
    <Form
      editorRef={editorRef}
      isSuperUser={isSuperUser}
      updateState={updateState}
      formData={formData}
    />
  );
  const handleSaveClick = () => handleConfirm(formData);
  const handleTogglePreviewClick = () => {
    if (!previewMode && format === "text") {
      if (editorRef.current) {
        setBody(editorRef.current.getContent());
      }
    }
    togglePreviewMode(!previewMode);
  };
  const previewButton = showPreviewButton && (
    <Fab
      sx={{ position: "fixed", right: "10%", top: "50%", zIndex: 100 }}
      variant="extended"
      color="warning"
      onClick={handleTogglePreviewClick}
    >
      {previewMode ? (
        <ModeEditOutlineIcon sx={{ mr: 1 }} />
      ) : (
        <VisibilityIcon sx={{ mr: 1 }} />
      )}
      {toggleText}
    </Fab>
  );
  const saveButton = previewMode && (
    <Button
      sx={{ position: "fixed", right: "10%", bottom: "30%", zIndex: 100 }}
      variant="contained"
      color="success"
      onClick={handleSaveClick}
    >
      Save Post
    </Button>
  );
  return (
    <div id="post-page__container">
      <main>
        {previewButton}
        {content}
        <article>{saveButton}</article>
      </main>
    </div>
  );
}

export default PostForm;
