import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import Skeleton from "@mui/material/Skeleton";

import { useAppDispatch } from "../../config/hooks";
import { getProfile, editProfile } from "../../actions/profiles";

import ProfileForm from "../../components/ProfileForm";
import { INITIAL_PROFILE_FORM } from "../../common/constants";

import {
  ConfirmDataTypes,
  PROFILE,
  ProfileFormData,
  SNACKBAR_STATUSES,
  UPDATE_PROFILE_REQUEST,
} from "../../@types";
import formatDateForDjango from "../../modules/formatDateForDjango";
import RCTVSnackbar from "../../components/Snackbar";
import uploadFilesToAws from "../../modules/uploadFilesToAws";

function EditProfile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [snackbarOpen, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<SNACKBAR_STATUSES>(
    SNACKBAR_STATUSES.SUCCESS
  );
  const [profileData, setProfileData] =
    useState<ProfileFormData>(INITIAL_PROFILE_FORM);
  const [showForm, toggleForm] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function getProfileFromParams(id: string | number) {
      const { payload } = await dispatch(getProfile(id));
      const retrievedProfile: PROFILE = payload.data[0] as PROFILE;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {
        id: profileId,
        date_modified,
        approved_by,
        approval_date,
        ...retrievedProfileData
      } = retrievedProfile;
      const retrievedProfileFormData: ProfileFormData = {
        ...retrievedProfileData,
      };
      setProfileData(retrievedProfileFormData);
      toggleForm(true);
    }
    if (id) {
      getProfileFromParams(id);
    }
  }, [dispatch, id]);

  const handleConfirm = async (data: ConfirmDataTypes) => {
    const newProfileData = data as ProfileFormData;
    if (id) {
      let profileImageLink = "";
      if (newProfileData.imageToSet) {
        const rawImages = (await uploadFilesToAws([
          newProfileData.imageToSet,
        ])) as any;
        const [profileImage] = await Promise.all(rawImages);
        profileImageLink = profileImage;
      }
      const profileDataToSend: UPDATE_PROFILE_REQUEST = {
        employee_name: newProfileData.employee_name,
        position: newProfileData.position,
        email: newProfileData.email,
        image: profileImageLink,
        department: newProfileData.department,
        id: id as unknown as number,
        date_modified: formatDateForDjango(new Date()),
      };

      const res = await dispatch(editProfile(profileDataToSend));
      if (res.meta.requestStatus === "rejected") {
        const msg =
          "Unable to edit profile! Please contact Jonathan for assistance";
        setSnackbarMessage(msg);
        setSnackbarSeverity(SNACKBAR_STATUSES.ERROR);
        setSnackbar(true);
        return;
      }
      navigate("/admin");
    }
    return;
  };

  const content = showForm ? (
    <ProfileForm
      formData={profileData}
      defaultDepartment={null}
      handleConfirm={handleConfirm}
      isSuperUser={false}
    />
  ) : (
    <Skeleton width="100%" />
  );

  return (
    <div id="post-page__container">
      <nav>
        <Link to="/admin">
          <article className="go-back">
            <HomeIcon />
            <h6>Go back to admin screen</h6>
          </article>
        </Link>
      </nav>
      <main>
        <header>
          <h4>Edit Profile</h4>
        </header>
        {content}
      </main>
      <RCTVSnackbar
        isOpen={snackbarOpen}
        severity={snackbarSeverity}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </div>
  );
}

export default EditProfile;
