import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router-dom";

import { Department } from "../../@types";
import { DEPARTMENT_KEY } from "../../common/constants";

import "./styles.scss";

interface MakeProfileButtonProps {
  department: Department;
}

function MakeProfileButton({ department }: MakeProfileButtonProps) {
  const navigate = useNavigate();
  const handleClick = () => {
    localStorage.setItem(DEPARTMENT_KEY, department);
    navigate(`/profile/create`);
  };
  return (
    <div className="button-design" onClick={handleClick}>
      <article className="content-position">
        <PersonAddIcon />
        <h6>Create Profile</h6>
      </article>
    </div>
  );
}

export default MakeProfileButton;
