import { motion } from "framer-motion";
import LinearProgress from "@mui/material/LinearProgress";

import { POST } from "../../../@types";

interface PostItemProps {
  isSelected: boolean;
  progress: number;
  post: POST;
  handleClick: Function;
  index: number;
}

function PostItem({
  post,
  isSelected,
  index,
  handleClick,
  progress,
}: PostItemProps) {
  const handleNavigation = () => {
    if (isSelected) {
      return null;
    }
    // set index and progress
    handleClick(index);
  };
  return (
    <article
      className={
        isSelected
          ? "carousel-tab__container--selected"
          : "carousel-tab__container"
      }
    >
      <LinearProgress
        sx={{
          backgroundColor: isSelected ? "white" : "darkgrey",
          "& .MuiLinearProgress-bar": {
            backgroundColor: "green",
          },
        }}
        variant="determinate"
        value={isSelected ? (progress / 6) * 100 : 0}
      />
      <motion.h6 whileHover={{ scale: 1.05 }} onClick={handleNavigation}>
        {post.title}
      </motion.h6>
    </article>
  );
}

export default PostItem;
