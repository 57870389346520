import logo from "../../assets/images/rctv-logo.png";

function Header() {
  return (
    <header className="logo__banner">
      <img src={logo} alt="Rare Collectibles logo" />
    </header>
  );
}

export default Header;
