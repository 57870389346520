import { useNavigate } from "react-router";

import IconButton from "@mui/material/IconButton";

import RateReviewIcon from "@mui/icons-material/RateReview";

import { POST } from "../../@types";
import DefaultImg from "../../assets/images/No_Image_Available.jpg";
import checkIfSuperUser from "../../modules/checkIfSuperUser";
import checkIfUserHasPrivileges from "../../modules/checkIfUserHasPrivileges";

interface AdminPostProps {
  editMode: boolean;
  data: POST;
  openDeleteModal: Function;
  viewApprovedAdminPosts: boolean;
}

function AdminPost({
  editMode,
  data,
  openDeleteModal,
  viewApprovedAdminPosts,
}: AdminPostProps) {
  const isSuperUser = checkIfSuperUser();
  const userHasEditPrivileges = checkIfUserHasPrivileges(data);
  const navigate = useNavigate();
  const showControls = userHasEditPrivileges || isSuperUser;
  const { tags, title, header_img: headerImg } = data;
  const goToEditPage = () => navigate(`/admin/edit/${data.id}`);
  const handleDeleteModal = () => openDeleteModal(data);
  const handleReviewButtonClick = () =>
    navigate(`/admin/review/post/${data.id}`);
  const imgToUse = headerImg && headerImg.length > 0 ? headerImg : DefaultImg;
  const deleteButton = editMode && (
    <article onClick={handleDeleteModal}>
      <span className="delete-post__btn">X</span>
    </article>
  );
  const editButton = editMode && (
    <article onClick={goToEditPage}>
      <span className="edit-post__btn">Edit</span>
    </article>
  );
  const reviewButton = isSuperUser && !viewApprovedAdminPosts && (
    <IconButton
      data-testid="review-button"
      sx={{ color: "#5cb85c" }}
      onClick={handleReviewButtonClick}
    >
      Review Post
      <RateReviewIcon sx={{ paddingLeft: 1 }} />
    </IconButton>
  );
  const tagsToShow = tags.map((t) => (
    <sub className="post__tag" key={t}>
      {t}
    </sub>
  ));
  return (
    <li className="admin-post__container">
      {showControls && (
        <article data-testid="review-actions" className="admin-post__actions">
          {deleteButton}
          {editButton}
        </article>
      )}
      <img className="admin-post__image" src={imgToUse} alt={title} />
      <article className="admin-post__content">
        {reviewButton}
        <h4>{title}</h4>
        <article className="tag__container">{tagsToShow}</article>
      </article>
    </li>
  );
}

export default AdminPost;
