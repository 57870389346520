import { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";

import { getPlaylistVideos, setMainVideo } from "../../actions/youtube";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { YoutubeVideo } from "../../@types";

import MainVideo from "./MainVideo";
import RelatedVideoThumbnail from "./RelatedVideoThumbnail";

interface YoutubePlaylistProps {
  playlistId: string;
  playlistName: string;
  handleClearCategory: Function;
}

const iconSx = { fontSize: 24 };
function YoutubePlaylist({
  handleClearCategory,
  playlistId,
  playlistName,
}: YoutubePlaylistProps) {
  const { videos, mainVideo } = useAppSelector((state) => state.youtube);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getPlaylistVideos(playlistId));
  }, [playlistId, dispatch]);
  const mainVideoIsReady = mainVideo.id !== -1;
  const mainVideoDiv = mainVideoIsReady && (
    <MainVideo mainVideoDetails={mainVideo} />
  );

  const handleThumbnailClick = (vid: YoutubeVideo) =>
    dispatch(setMainVideo(vid));

  const handleGoBack = () => handleClearCategory();

  return (
    <div className="youtube__playlist-container">
      <nav onClick={handleGoBack} className="go-back__container">
        <ArrowBackIcon sx={iconSx} />
        <span className="go-back--text">Go back</span>
      </nav>
      <header className="youtube__playlist-section-header">
        {playlistName}
      </header>
      <div className="youtube__playlist-wrapper">
        <section className="current-video__container">{mainVideoDiv}</section>
        <menu className="related-video__container">
          <ul className="related-video__list">
            {videos.map((vid) => (
              <RelatedVideoThumbnail handleThumbnailClick={handleThumbnailClick} videoDetails={vid} key={vid.id} />
            ))}
          </ul>
        </menu>
      </div>
    </div>
  );
}

export default YoutubePlaylist;
