import { useState } from "react";
import { Link } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";

import RCTVSnackbar from "../../components/Snackbar";
import EventForm from "../../components/EventForm";

import {
  ConfirmDataTypes,
  Department,
  EventFormData,
  EVENT_REQUEST,
  SNACKBAR_STATUSES,
} from "../../@types";
import { INITIAL_EVENT_FORM } from "../../common/constants";
import checkIfSuperUser from "../../modules/checkIfSuperUser";
import formatDateTimeForDjango from "../../modules/formatDateTimeForDjango";
import getUserDepartment from "../../modules/getUserDepartment";
import { useAppDispatch } from "../../config/hooks";
import { createEvents } from "../../actions/events";

import "./styles.scss";

function CreateEvent() {
  const dispatch = useAppDispatch();
  const isSuperUser = checkIfSuperUser();
  const [eventData, setEventData] = useState<EventFormData>(INITIAL_EVENT_FORM);
  const [snackbarOpen, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<SNACKBAR_STATUSES>(
    SNACKBAR_STATUSES.SUCCESS
  );
  const resetState = () => setEventData(INITIAL_EVENT_FORM);
  const showSnackbarMessage = (msg: string, severity: SNACKBAR_STATUSES) => {
    setSnackbarMessage(msg);
    setSnackbarSeverity(severity);
    return setSnackbar(true);
  };
  const handleConfirm = async (data: ConfirmDataTypes) => {
    const formData = data as EventFormData;
    const {
      start_date: startDate,
      end_date: endDate,
      title: eventTitle,
      event_description: eventDescription,
      tags,
      start_time,
      end_time,
      rrule,
    } = formData;
    // format dates
    const formattedStartDate = formatDateTimeForDjango(startDate, start_time);
    const formattedEndDate = formatDateTimeForDjango(endDate, end_time);
    // get all data
    const userDepartment = getUserDepartment();
    const userDepartmentToSet: Array<Department> = userDepartment
      ? [userDepartment]
      : ["All"];
    const tagsToSend = isSuperUser ? tags : userDepartmentToSet;
    const dataObj: EVENT_REQUEST = {
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      title: eventTitle,
      event_description: eventDescription,
      tags: tagsToSend,
    };
    if (rrule) {
      const rrulestr = rrule.toString();
      dataObj.rrulestr = rrulestr;
      dataObj.end_date = undefined;
    }
    // do api call
    const res = await dispatch(createEvents(dataObj));
    // get response
    if (res.type === "CREATE_EVENT/fulfilled") {
      // render snackbar
      resetState();
      return showSnackbarMessage(
        "Successfully created event!",
        SNACKBAR_STATUSES.SUCCESS
      );
    }
    return showSnackbarMessage(
      "Unable to create event, please contact Jonathan",
      SNACKBAR_STATUSES.ERROR
    );
  };

  return (
    <div id="event-page__container">
      <nav>
        <Link to="/admin">
          <article className="go-back">
            <HomeIcon />
            <h6>Go back to admin screen</h6>
          </article>
        </Link>
      </nav>
      <main className="create-events__container">
        <header className="create-events__header">
          <h4>Create Event</h4>
        </header>
        <EventForm
          handleConfirm={handleConfirm}
          eventData={eventData}
          isSuperUser={isSuperUser}
        />
      </main>
      <RCTVSnackbar
        isOpen={snackbarOpen}
        severity={snackbarSeverity}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </div>
  );
}

export default CreateEvent;
