import React from "react";
import { createRoot } from "react-dom/client";
import { Worker } from "@react-pdf-viewer/core";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";

import store, { persistor } from "./store";
import { WORKER_URL } from "./common/constants";
import reportWebVitals from "./reportWebVitals";

import "./index.scss";
import '@react-pdf-viewer/core/lib/styles/index.css';


globalThis.React = React;

window.Buffer = window.Buffer || require("buffer").Buffer;

const container: HTMLElement = document.getElementById("root")!;
const root = createRoot(container);

export const AppWrapper = () => (
  <Worker workerUrl={WORKER_URL}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Worker>
);

root.render(AppWrapper());

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
