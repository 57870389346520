import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  clearPosts,
  createPosts,
  deletePost,
  editPost,
  getPosts,
} from "../actions/posts";
import { POST } from "../@types";

interface POSTS_INITIAL_STATE {
  posts: Array<POST>;
  status: "idle" | "pending" | "succeeded" | "failed";
  error: any;
}

const initialState: POSTS_INITIAL_STATE = {
  posts: [],
  status: "idle",
  error: null,
};

const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {},

  extraReducers(builder: ActionReducerMapBuilder<POSTS_INITIAL_STATE>) {
    builder.addCase(getPosts.rejected, (_state, action) => ({
      ...initialState,
      status: "failed",
      error: action,
    }));
    builder.addCase(getPosts.fulfilled, (_state, action) => ({
      posts: action.payload.data,
      error: null,
      status: "succeeded",
    }));
    builder.addCase(createPosts.fulfilled, (state, action) => {
      const { post } = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.posts));
      newArr.push(post);
      state.posts = newArr;
    });
    builder.addCase(deletePost.fulfilled, (state, action) => {
      const postId = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.posts));
      const postIdx = state.posts.find((p) => p.id === postId);
      newArr.splice(postIdx, 1);
      state.posts = newArr;
    });
    builder.addCase(clearPosts.fulfilled, (state) => ({ ...initialState }));
    builder.addCase(editPost.fulfilled, (state, action) => {
      const postToEdit: POST = action.payload as unknown as POST;
      const newArr = JSON.parse(
        JSON.stringify(state.posts)
      ) as unknown as Array<POST>;
      const postIdx = state.posts.findIndex((p) => p.id === postToEdit.id);
      newArr[postIdx] = postToEdit;
      state.posts = newArr;
    });
  },
});

export default postsSlice.reducer;
