import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

interface GoBackButtonProps {
  link: string;
}

function GoBackButton({ link }: GoBackButtonProps) {
  return (
    <nav>
      <Link to={link}>
        <article className="go-back">
          <HomeIcon />
          <h6>Go back to Home Page</h6>
        </article>
      </Link>
    </nav>
  );
}

export default GoBackButton;
