import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { rrulestr } from "rrule";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";

import EventModal from "./EventModal";

import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getEvents } from "../../actions/events";
import { RCTVDate, RCTV_CALENDAR_EVENT } from "../../@types";

import "./styles.scss";

const localizer = momentLocalizer(moment);

function EventCalendar() {
  const dispatch = useAppDispatch();
  const events = useAppSelector((state) => state.events.calendarEvents);
  const status = useAppSelector((state) => state.events.status);
  const [savedEvents, setEvents] = useState<Array<RCTV_CALENDAR_EVENT>>([]);
  const [backgroundEvents, setBackgroundEvents] = useState<Array<RCTV_CALENDAR_EVENT>>([])
  const [selectedEvent, setSelectedEvent] = useState<RCTV_CALENDAR_EVENT>();
  const [showEventDescription, toggleDescriptionModal] = useState(false);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getEvents());
    }
    if (status === "succeeded") {
      const eventsToSet: Array<RCTV_CALENDAR_EVENT> = [];
      const repeatingEvents = [];
      // const birthdayEvents = events.filter((e) =>
      //   e.title.toLowerCase().includes("birthday")
      // );
      for (let i = 0; i < events.length; i += 1) {
        const eventRrule = events[i].rrulestr;
        if (!eventRrule) {
          eventsToSet.push(events[i]);
        } else {
          if (eventRrule && eventRrule.length > 0) {
            repeatingEvents.push(events[i]);
          }
        }
      }
      if (repeatingEvents.length > 0) {
        // access each repeating event
        for (let i = 0; i < repeatingEvents.length; i += 1) {
          const rrulestrToParse = repeatingEvents[i].rrulestr;
          if (rrulestrToParse && rrulestrToParse.length > 0) {
            try {
              const parsedRrule = rrulestr(rrulestrToParse);
              // get all dates from repeating rule
              const allEvents = parsedRrule.all();
              for (let j = 0; j < allEvents.length; j += 1) {
                // format object for event
                const eventToSet: RCTV_CALENDAR_EVENT = {
                  title: repeatingEvents[i].title,
                  event_description: repeatingEvents[i].event_description,
                  label_id: repeatingEvents[i].label_id,
                  id: repeatingEvents[i].id,
                  start_date: new RCTVDate(allEvents[j]).addDays(1),
                  end_date: new RCTVDate(allEvents[j]),
                  tags: repeatingEvents[i].tags,
                };
                // append to eventsToSet
                eventsToSet.push(eventToSet);
              }
            } catch (err) {
              // TODO: FIND SOME ERROR HANDLER THAT WILL ALERT MIKE FOR OBJECTS THAT FAIL TO RENDER
              continue;
            }
          }
        }
      }
      setEvents(eventsToSet);
    }
  }, [events, status, dispatch]);

  const handleSelectedEvent = (event: any) => {
    setSelectedEvent(event);
    toggleDescriptionModal(true);
  };
  const closeDescriptionModal = () => toggleDescriptionModal(false);

  return (
    <div>
      <Calendar
        selectable
        localizer={localizer}
        startAccessor="start_date"
        endAccessor="end_date"
        style={{ height: 1550 }}
        backgroundEvents={backgroundEvents}
        events={savedEvents}
        onSelectEvent={(e) => handleSelectedEvent(e)}
        popup={true}
      />
      {selectedEvent && (
        <EventModal
          rctvEvent={selectedEvent}
          isOpen={showEventDescription}
          handleClose={closeDescriptionModal}
        />
      )}
    </div>
  );
}

export default EventCalendar;
