import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { RCTV_CALENDAR_EVENT, ModalProps } from "../../@types";

interface EventModalProps extends ModalProps {
  rctvEvent: RCTV_CALENDAR_EVENT;
}

function EventModal({ handleClose, isOpen, rctvEvent }: EventModalProps) {
  const { event_description, title } = rctvEvent;
  const handleCloseClick = () => handleClose();
  return (
    <Modal open={isOpen}>
      <Box>
        <div className="modal__container">
          <header className="modal__header">{title}</header>
          <section className="event-description__text modal__content">
            {event_description}
          </section>
          <footer>
            <Button
              variant="text"
              color="info"
              style={{ color: "grey" }}
              onClick={handleCloseClick}
            >
              Close
            </Button>
          </footer>
        </div>
      </Box>
    </Modal>
  );
}

export default EventModal;
