import ReactHTMLParser from "react-html-parser";
import { motion } from "framer-motion";
import { Viewer } from "@react-pdf-viewer/core";

import {
  postBodyVariant,
  postImageVariant,
  postSubtitleVariant,
  postTagsContainerVariant,
  postTagsVariant,
  postTitleVariant,
  storyContainerVariant,
} from "./animations";
import { POST } from "../../@types";
import getYoutubeVideoId from "../../modules/getYoutubeId";

interface PostContentProps {
  post: POST;
}

function PostContent({ post }: PostContentProps) {
  const {
    header_img: headerImg,
    title,
    subtitle: subTitle,
    body,
    publish_date: publishDate,
    tags,
    pdf_link: pdfLink,
    format,
  } = post;
  let imgSrc = "";
  if (headerImg) {
    imgSrc = headerImg;
  }
  const content = () => {
    if (format === "image") {
      return (
        <section className="body-img__container">
          <img src={body} alt="body for post" />
        </section>
      );
    }
    if (format === "pdf") {
      return (
        <section className="body-img__container">
          <Viewer fileUrl={pdfLink} />
        </section>
      );
    }
    if (format === "video") {
      const youtubeId = getYoutubeVideoId(body);
      if (youtubeId === "Error") {
        return (
          <article className="post-video__wrapper">
            <h1 data-testid="post-error-message">
              Invalid youtube link, please contact the administrator and get
              this post fixed
            </h1>
          </article>
        );
      }
      return (
        <article className="post-video__wrapper">
          <iframe
            data-testid="rctv-video"
            width="560"
            height="315"
            allowFullScreen
            src={`//www.youtube.com/embed/${youtubeId}?rel=0`}
            title={title}
          />
        </article>
      );
    }
    return (
      <section data-testid="rctv-article" className="story__body">
        {ReactHTMLParser(body)}
      </section>
    );
  };
  const formattedDate = new Date(publishDate).toDateString();
  const tagsString: string = tags ? tags.join(", ") : "";
  const postContent = content();
  return (
    <div className="story__container">
      <motion.section
        variants={storyContainerVariant}
        initial="initial"
        animate="animate"
        className="story__title"
      >
        <motion.header variants={postTitleVariant}>{title}</motion.header>
        <motion.h6 variants={postSubtitleVariant}>{subTitle}</motion.h6>
        <motion.img
          variants={postImageVariant}
          className="story__image"
          src={imgSrc}
          alt={imgSrc}
        />
      </motion.section>
      <motion.article
        variants={postTagsContainerVariant}
        initial="initial"
        animate="animate"
        className="story-misc__container"
      >
        <motion.span variants={postTagsVariant}>{tagsString}</motion.span>
        <motion.sub variants={postTagsVariant}>{formattedDate}</motion.sub>
      </motion.article>
      <motion.section
        animate="animate"
        initial="initial"
        variants={postBodyVariant}
        className="story__body"
      >
        {postContent}
      </motion.section>
    </div>
  );
}

export default PostContent;
